import React, {FunctionComponent, ReactElement} from "react";
import * as styles from "./LayoutContentArea.module.scss";

export const LayoutContentArea: FunctionComponent<{ aside?: ReactElement }> = ({children, aside}) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.content}>
        {children}
      </div>
      {aside && <div className={styles.asideContainer}>{aside}</div>}
    </div>
  )
}
