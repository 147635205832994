import {t} from "../i18n";
import {ILanguageBundleOrLocalized, Language} from "./LanguageBundle";

export interface IDateRange {
  from: Date
  to?: Date
  note?: ILanguageBundleOrLocalized

  getPeriodInformation(language: Language): string
}

export default class DateRange implements IDateRange {
  from: Date
  to?: Date
  note?: ILanguageBundleOrLocalized

  constructor(param: Omit<IDateRange, 'getPeriodInformation'>) {
    const {
      from,
      to,
      note,
    } = param
    this.from = from
    if (to) this.to = to
    if (note) this.note = note
  }

  getPeriodInformation(language: Language) {
    const since = t({de: 'Seit', en: 'Since'}, language)
    const fromDate = this.getLocalizedMonthAndYear(this.from, language)

    return (this.to)
      ? `${fromDate} - ${this.getLocalizedMonthAndYear(this.to, language)}`
      : `${since} ${fromDate}`
  }

  getLocalizedMonthAndYear(date: Date, locale: string = 'de-DE') {
    const options = {year: 'numeric', month: 'long'} as const;
    return date.toLocaleDateString(locale, options);
  }
}
