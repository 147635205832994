import React from 'react';
import {Button, Menu, MenuItem, Wrapper} from 'react-aria-menubutton';
import * as styles from './TopMenu.module.scss'
import {IGenericLanguageBundle, Language} from "../model/LanguageBundle";
import {localizer} from "../i18n";

interface Link {
  value: string
  href: string
}

const linkBundle: IGenericLanguageBundle<Link>[] = [
  {
    de: {
      value: 'Curriculum Vitae',
      href: '/'
    },
    en: {
      value: 'Resume',
      href: '/en/'
    }
  },
  {
    de: {
      value: 'Häufig gestellte Fragen / FAQ',
      href: '/faq/'
    },
    en: {
      value: 'Frequently asked Questions',
      href: '/en/faq/'
    }
  }
]

const linksDe: Link[] = [
  {
    value: 'CV in 3. Person Singular',
    href: '/bastian-nolte-3ps/'
  },
  {
    value: 'Impressum',
    href: '/impressum/#impressum-und-datenschutz'
  },
  {
    value: 'Datenschutz',
    href: '/impressum/#datenschutz'
  }
]

// https://github.com/davidtheclark/react-aria-menubutton
export const TopMenu = ({language, href}: { language: Language, href: string }) => {
  const t = localizer(language)
  const linkList = [
    ...linkBundle.map(link => t(link)),
    ...((language === "de") ? linksDe : [])
  ]
  const url = new URL(href)
  const menuItems = linkList
    .filter(link => link.href !== url.pathname + url.hash)
    .map((link, index) => {
      return (
        <MenuItem key={index} value={link.href} text={link.value}>
          {link.value}
        </MenuItem>
      );
    });

  return (
    <Wrapper
      id="top-menu"
      onSelection={handleSelection}
    >
      <Button className={styles.menuButton} aria-label="Hauptmenü">
        <span className={styles.iconBar}/>
        <span className={styles.iconBar}/>
        <span className={styles.iconBar}/>
        <span className={styles.srOnly}/>
      </Button>
      <Menu className={styles.navDropdown}>
        <div>{menuItems}</div>
      </Menu>
    </Wrapper>
  );

  function handleSelection(href: string) {
    document.location.href = href
  }
}
