import {ITechnology} from "../model/Technology";
import {IPattern} from "../model/Pattern";

export const patterns: IPattern[] = [
  {
    id: "E105D3F7-76B3-4E7C-B040-DB2A9F56CE9F",
    name: "Adapter pattern",
    aliases: {
      de: ["Adapter Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Adapter_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "6d6e71ba-dfd7-446b-bcbe-55e17a43ebe4",
    name: "Architectural pattern",
    aliases: {
      de: ["Architekturmuster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Architekturmuster",
    type: "pattern"
  },
  {
    id: "3764086A-F62D-4EA0-9AAB-6C39969DA640",
    name: "Atomic design",
    description: "Atomic Design ist ein Konzept zur Organisation von Web Layouts. Das Ziel von Atomic Design besteht darin, komplexe Projekte konsistent zu gestalten und übersichtliche Sinneinheiten zu erzeugen, was wiederum die Zusammenarbeit vereinfachen soll.",
    urls: ["https://bradfrost.com/blog/post/atomic-web-design/", "https://atomicdesign.bradfrost.com/", "https://t3n.de/news/atomic-design-baukastensystem-721010/"],
    type: "pattern"
  },
  {
    id: "8CDCA296-96E8-48E4-ADE8-6EAAF4E425A0",
    name: "Observer pattern",
    aliases: {
      de: ["Beobachter Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Beobachter_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "7A8FA5AF-E044-4778-B135-A29BA20F6A6F",
    name: "Visitor pattern",
    aliases: {
      de: ["Besucher Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Besucher_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "4A51EE36-997B-443A-AEA2-8301282CD567",
    name: "Bridge pattern",
    aliases: {
      de: ["Brücke Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Br%C3%BCcke_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "6022CA27-6BFE-421A-96A0-FF6C33E6D7BB",
    name: "Command query responsibility segregation",
    aliases: {
      de: ["CQRS", "Kommando-Abfrage-Zuständigkeits-Trennung"],
      en: ["CQRS"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Command-Query-Responsibility-Segregation",
    type: "pattern"
  },
  {
    id: "847313EA-D985-4953-82E2-10029D11CE3D",
    name: "Data Access Object",
    aliases: {
      de: ["DAO", "Datenzugriffsobjekt"],
      en: ["DAO"]
    },
    description: "Data Access Object ist ein Entwurfsmuster, das den Zugriff auf unterschiedliche Arten von Datenquellen so kapselt, dass die angesprochene Datenquelle ausgetauscht werden kann, ohne dass der aufrufende Code geändert werden muss. Dadurch soll die eigentliche Programmlogik von technischen Details der Datenspeicherung befreit werden und flexibler einsetzbar sein.",
    urls: ["https://www.oracle.com/java/technologies/dataaccessobject.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Data_Access_Object",
    type: "pattern"
  },
  {
    id: "9DB0BFE0-C2E9-4D57-9AEE-B8F16C0D0147",
    name: "Data Transfer Object",
    aliases: {
      de: ["DTO", "Datentransferobjekt"],
      en: ["DTO"]
    },
    description: "Das Transferobjekt oder Datentransferobjekt ist ein Entwurfsmuster, das mehrere Daten in einem Objekt bündelt, sodass sie durch einen einzigen Programmaufruf übertragen werden können.",
    wikiUrl: "https://de.wikipedia.org/wiki/Transferobjekt",
    type: "pattern"
  },
  {
    id: "91EB99C2-97A2-4DD5-A3C8-F70579922D82",
    name: "Decorator pattern",
    aliases: {
      de: ["Decorator Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Decorator",
    type: "pattern"
  },
  {
    id: "9A9D2334-53B6-4D12-9D77-401AE08563E6",
    name: "Dependency inversion principle",
    aliases: {
      de: ["DIP", "Abhängigkeits-Umkehr-Prinzip"],
      en: ["DIP"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Dependency-Inversion-Prinzip",
    type: "pattern"
  },
  {
    id: "35aea2be-fb33-406e-829e-9858459bfcc1",
    name: "Domain driven design",
    aliases: ["DDD"],
    wikiUrl: "https://de.wikipedia.org/wiki/Domain-driven_Design",
    type: "pattern"
  },
  {
    id: "C681280C-8F4E-4A3F-BB9A-F897DD2651C8",
    name: "Double-checked locking",
    aliases: {
      de: ["doppelt überprüfte Sperrung"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Doppelt_%C3%BCberpr%C3%BCfte_Sperrung",
    type: "pattern"
  },
  {
    id: "DF076B52-CD91-46F8-B6E1-D4D065DE08A9",
    name: "Builder pattern",
    aliases: {
      de: ["Erbauer Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Erbauer_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "C2E11C4C-9574-4183-BAD0-5144716B603A",
    name: "Enterprise architecture",
    aliases: {
      de: ["Unternehmensarchitektur"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Unternehmensarchitektur",
    type: "pattern"
  },
  {
    id: "C93E2468-5805-4575-B43E-E67AFB2A7C71",
    name: "Event-driven architecture",
    aliases: {
      de: ["Ereignisgesteuerte Architektur"],
      en: []
    },
    wikiUrl: "https://en.wikipedia.org/wiki/Event-driven_architecture",
    type: "pattern"
  },
  {
    id: "E0804202-DA35-4828-8B25-32E6AA47697A",
    name: "Event sourcing",
    aliases: ["ES"],
    urls: ["https://martinfowler.com/eaaDev/EventSourcing.html", "https://microservices.io/patterns/data/event-sourcing.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Event_Sourcing",
    type: "pattern"
  },
  {
    id: "037DE745-3A10-4E47-B1A1-8B3769979829",
    name: "Factory method",
    aliases: {
      de: ["Fabrikmethode"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Fabrikmethode",
    type: "pattern"
  },
  {
    id: "F09FFD9D-DB0F-4D0F-ACFE-BEE21FC658C3",
    name: "Facade pattern",
    aliases: {
      de: ["Fassade Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Fassade_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "860C15F7-8A1C-48DC-84B2-A0DA370F16E2",
    name: "Hash-based Sharding",
    type: "pattern"
  },
  {
    id: "E4DC0F52-2A00-463D-A669-64FF3810BA66",
    name: "Interface-segregation principle",
    aliases: {
      de: ["ISP", "Schnittstellenaufteilungsprinzip"],
      en: ["ISP"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Interface-Segregation-Prinzip",
    type: "pattern"
  },
  {
    id: "F0BA221E-8DC9-47F3-A809-CDE9FEB4BE2B",
    name: "Composite pattern",
    aliases: {
      de: ["Kompositum Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Kompositum_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "A0F7E5BC-0ACA-41CE-A6D3-4164464DA081",
    name: "Liskov substitution principle",
    aliases: {
      de: ["LSP", "Liskovsches Substitutionsprinzip", "Ersetzbarkeitsprinzip"],
      en: ["LSP"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Liskovsches_Substitutionsprinzip",
    type: "pattern"
  },
  {
    id: "C6339454-50D0-4F75-BBA6-9B0E135C36BB",
    name: "Memento pattern",
    aliases: {
      de: ["Token pattern", "Memento Muster"],
      en: ["Token pattern"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Memento_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "F1C12574-D210-46C5-8444-E489EAE6B763",
    name: "Model view controller",
    aliases: {
      de: ["MVC", " Modell-Präsentation-Steuerung"],
      en: ["MVC"]
    },
    description: "Model View Controller ist ein Muster zur Unterteilung einer Software in die drei Komponenten «Datenmodell», «Ansicht» und «Programmsteuerung», dass sowohl als Architekturmuster als auch als Entwurfsmuster eingesetzt werden kann. So soll ein flexibler Programmentwurf entstehen, der offen für Anpassungen und Erweiterungen ist und die Wiederverwendbarkeit einzelner Komponenten ermöglicht.",
    wikiUrl: "https://de.wikipedia.org/wiki/Model_View_Controller",
    type: "pattern"
  },
  {
    id: "A648A0C1-90F3-4015-9229-272745B0021A",
    name: "Model view presenter",
    aliases: {
      de: ["MVP", "Modell-Ansicht-Präsentierer"],
      en: ["MVP"]
    },
    description: "Model View Presenter ist ein Muster zur Unterteilung einer Software in die drei Komponenten «Datenmodell», «Ansicht» und «Präsentierer».",
    wikiUrl: "https://de.wikipedia.org/wiki/Model_View_Presenter",
    type: "pattern"
  },
  {
    id: "43E5A46F-89D6-4E77-B772-3B278854F1C2",
    name: "Null object pattern",
    aliases: {
      de: ["Nullobjekt Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Nullobjekt_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "3F8AEB02-CED7-40B3-8FF7-D9F91D9A5883",
    name: "Open–closed principle",
    aliases: {
      de: ["OCP", "Prinzip der Offen- und Verschlossenheit"],
      en: ["OCP"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Open-Closed-Prinzip",
    type: "pattern"
  },
  {
    id: "DEA9758E-AF2D-48EC-A320-4ED919066419",
    name: "Prototype pattern",
    aliases: {
      de: ["Prototyp Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Prototyp_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "b02427c8-5acb-4f64-88fd-d29c1ff94a3c",
    name: "PRPL pattern",
    description: "PRPL ist ein Akronym, das ein Muster beschreibt, mit dem Webseiten schneller geladen und interaktiv gemacht werden können.",
    aliases: ["Push (or preload), Render, Pre-cache, Lazy Load"],
    urls: ["https://web.dev/apply-instant-loading-with-prpl/"],
    type: "pattern"
  },
  {
    id: "F673BA7B-F063-4592-A334-D58434007C70",
    name: "Publish–subscribe pattern",
    aliases: {
      de: ["Veröffentlichen & Abbonieren-Muster"],
      en: []
    },
    wikiUrl: "https://en.wikipedia.org/wiki/Publish%E2%80%93subscribe_pattern",
    type: "pattern"
  },
  {
    id: "124D9730-B466-40A0-9368-5EF6610EFF90",
    name: "Range-based sharding pattern",
    type: "pattern"
  },
  {
    id: "84832B62-8A09-4C0B-AFB4-089376EDB11D",
    name: "Readers–writer lock",
    wikiUrl: "https://en.wikipedia.org/wiki/Readers%E2%80%93writer_lock",
    type: "pattern"
  },
  {
    id: "e3d261e1-7fae-4815-a2b9-8ab5b4012f34",
    name: "Saga Transaction Pattern",
    aliases: ["Saga distributed transactions"],
    urls: [
      "https://docs.microsoft.com/en-us/azure/architecture/reference-architectures/saga/saga",
      "https://microservices.io/patterns/data/saga.html",
      "https://medium.com/@ijayakantha/microservices-the-saga-pattern-for-distributed-transactions-c489d0ac0247"
    ],
    type: "pattern"
  },
  {
    id: "23E7C08E-950F-4446-B44D-DB31D6BD9E8C",
    name: "Service-oriented architecture",
    aliases: {
      de: ["SOA", "Diensteorientierte Architektur"],
      en: ["SOA"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Serviceorientierte_Architektur",
    type: "pattern"
  },
  {
    id: "34871205-FCC2-4350-B293-F8AC7A7C3FC4",
    name: "Circuit breaker pattern",
    aliases: {
      de: ["Sicherungsmuster"],
      en: []
    },
    urls: ["https://martinfowler.com/bliki/CircuitBreaker.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Sicherung_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "EC307B31-BBFE-46E6-B050-E13B67C711D5",
    name: "Single responsibility principle",
    aliases: {
      de: ["Single-Responsibility-Prinzip", "Prinzip der eindeutigen Verantwortlichkeit"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Single-Responsibility-Prinzip",
    type: "pattern"
  },
  {
    id: "B6F20C87-5908-4EF2-BEDD-39BABABDB527",
    name: "Singleton pattern",
    wikiUrl: "https://de.wikipedia.org/wiki/Singleton_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "27AA01AE-62C9-4990-8672-0BD91684047F",
    name: "Proxy pattern",
    aliases: {
      de: ["Stellvertreter Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Stellvertreter_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "B028FE34-2D47-4769-BBBC-82C1FA64A894",
    name: "Strategy pattern",
    aliases: {
      de: ["policy pattern", "Strategie Muster"],
      en: ["policy pattern"]
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Strategie_(Entwurfsmuster)",
    type: "pattern"
  },
  {
    id: "f57612f2-68f7-47ef-93b0-70dd4da63e09",
    name: "Static content hosting pattern",
    urls: [
      "https://docs.microsoft.com/en-us/azure/architecture/patterns/static-content-hosting"
    ],
    type: "pattern"
  },
  {
    id: "DC0BF223-A62F-48E3-B234-633BBEE3C080",
    name: "Thread pool pattern",
    wikiUrl: "https://en.wikipedia.org/wiki/Thread_pool",
    type: "pattern"
  },
  {
    id: "cd117411-b45a-4976-8b0f-c3a645f86f69",
    name: "Throttling pattern",
    aliases: {
      de: ["Drossellungs-Muster"],
      en: []
    },
    urls: [
      "https://docs.microsoft.com/en-us/azure/architecture/patterns/throttling"
    ],
    type: "pattern"
  },
  {
    id: "FAD7E387-7BE5-4CC4-871B-6E0E27C2AAAC",
    name: "Vertical Sharding Pattern",
    type: "pattern"
  },
  {
    id: "8A74507D-A8CC-43DF-A117-FA9B5345304B",
    name: "State pattern",
    aliases: {
      de: ["Zustands-Muster"],
      en: []
    },
    wikiUrl: "https://de.wikipedia.org/wiki/Zustand_(Entwurfsmuster)",
    type: "pattern"
  }
]

export const technologies: ITechnology[] = [
  {
    id: "e1750306-e3d5-4efa-88bf-910758902d5c",
    name: "{less}",
    aliases: ["lesscss"],
    urls: ["http://lesscss.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Less_(Stylesheet-Sprache)",
    type: "technology"
  },
  {
    id: "243818c8-9b5f-41b3-8c9b-e5e27c8ae33a",
    name: "$httpBackend",
    wikiUrl: "https://docs.angularjs.org/api/ngMock/service/$httpBackend",
    type: "technology"
  },
  {
    id: "549e8dc0-2a8d-4e89-9551-c6f168c37bdc",
    name: "adcubum SYRIUS",
    aliases: ["Assekuranzsoftware"],
    urls: ["https://www.adcubum.com/de/unsere-loesung"],
    type: "technology"
  },
  {
    id: "f140e7eb-9690-47de-b894-27947169f352",
    name: "Adobe Flash",
    wikiUrl: "https://de.wikipedia.org/wiki/Adobe_Flash",
    type: "technology"
  },
  {
    id: "afb5fc1f-2dba-43b8-b128-73f6ac3bc445",
    name: "Adobe Framemaker",
    urls: ["https://www.adobe.com/ch_de/products/framemaker.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/FrameMaker",
    type: "technology"
  },
  {
    id: "8e13ee2c-db76-4175-ad70-3634d16b686c",
    name: "Adobe XD",
    wikiUrl: "https://en.wikipedia.org/wiki/Adobe_XD",
    urls: ["https://www.adobe.com/products/xd.html"],
    type: "technology"
  },
  {
    id: "fc3530da-382f-4b46-a2dc-ab099d8e1301",
    name: "Airlock Web Application Firewall",
    aliases: ["WAF"],
    urls: ["https://www.airlock.com/secure-access-hub/komponenten/web-application-firewall-waf"],
    type: "technology"
  },
  {
    id: "b84d32d0-7027-43f0-b7d2-7cf8e4462865",
    name: "AMaViS",
    aliases: ["A MAil Virus Scanner"],
    urls: ["http://amavis.sourceforge.net/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Amavis",
    type: "technology"
  },
  {
    id: "bc611a2f-d07a-4af0-97b2-8312749ea847",
    name: "Angular",
    urls: ["https://angular.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Angular",
    type: "technology"
  },
  {
    id: "2f97a61b-4cde-4a8a-be22-50fb156d7b12",
    name: "Angular CLI",
    urls: ["https://angular.io/cli"],
    type: "technology"
  },
  {
    id: "892de921-659b-47af-bfc2-8a06755ae0cb",
    name: "Angular Material",
    urls: ["https://material.angular.io/"],
    type: "technology"
  },
  {
    id: "f8bdb71d-4d74-4473-b63c-08e4af7d1bd7",
    name: "Angular UI Grid",
    urls: ["http://ui-grid.info/"],
    type: "technology"
  },
  {
    id: "50f313a2-8edf-4d0b-9cba-980c5c528f9b",
    name: "AngularJS",
    urls: ["https://angularjs.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/AngularJS",
    type: "technology"
  },
  {
    id: "78c43155-505e-466c-b96e-bce94070e08c",
    name: "Apache Ant",
    aliases: ["Ant"],
    urls: ["https://ant.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Ant",
    type: "technology"
  },
  {
    id: "577974c6-b5d2-4e48-8c7d-5ef9ed18ffb4",
    name: "Apache Collections",
    urls: ["https://commons.apache.org/proper/commons-collections/"],
    type: "technology"
  },
  {
    id: "86e17b4a-73c3-4f25-9ce6-155dc45e8e0c",
    name: "Apache Commons",
    urls: ["https://commons.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Commons",
    type: "technology"
  },
  {
    id: "994e3301-a720-46b3-8367-03ffed28a203",
    name: "Apache Cordova",
    urls: ["https://cordova.apache.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Apache_Cordova",
    type: "technology"
  },
  {
    id: "e5e1e5f3-b307-425e-888d-bf83ede9c146",
    name: "Apache DeltaSpike",
    urls: ["https://deltaspike.apache.org/"],
    wikiUrl: "",
    type: "technology"
  },
  {
    id: "d3779cb7-a448-40a1-a20b-1a4e0cabd4ff",
    name: "Apache HTTP Server",
    urls: ["https://httpd.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_HTTP_Server",
    type: "technology"
  },
  {
    id: "90c1f8b3-668f-447f-8515-1d8fbec66741",
    name: "Apache Log4J",
    aliases: ["Log4J"],
    urls: ["https://logging.apache.org/log4j/2.x/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Log4j",
    type: "technology"
  },
  {
    id: "7edf1ac7-631b-4bd3-a036-e84aefc2a393",
    name: "Apache Lucene",
    urls: ["https://lucene.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Lucene",
    type: "technology"
  },
  {
    id: "7ba7f7db-6cc9-4e6b-8f45-1f1eebaca972",
    name: "Apache Maven",
    aliases: ["Maven"],
    urls: ["https://maven.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Maven",
    type: "technology"
  },
  {
    id: "834e9fe3-6f03-40e7-a7a5-46fd668a28b9",
    name: "Apache POI",
    aliases: ["Java API for Microsoft Documents"],
    urls: ["https://poi.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_POI",
    type: "technology"
  },
  {
    id: "c3a64cef-fd6e-48f0-b82e-20f90ad6c103",
    name: "Apache Solr™️",
    urls: ["https://lucene.apache.org/solr/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Apache_Solr",
    type: "technology"
  },
  {
    id: "f3f761f5-2ff4-40dc-8dd8-a1caf5ae06cb",
    name: "Apache SpamAssassin",
    urls: ["https://spamassassin.apache.org/"],
    type: "technology"
  },
  {
    id: "d56e39fc-1842-4602-8e9a-9f7a7e491fff",
    name: "Apache Subversion",
    aliases: ["Subversion"],
    urls: ["https://subversion.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Subversion",
    type: "technology"
  },
  {
    id: "a0a7cf91-2b7f-46d3-ab07-e41a97f04d7a",
    name: "Apache Tomcat® (cluster)",
    aliases: ["Tomcat"],
    urls: ["http://tomcat.apache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Apache_Tomcat",
    type: "technology"
  },
  {
    id: "2282a694-1883-49c7-8156-330fc2e1daf3",
    name: "Apache TomEE",
    aliases: ["Java EE Web Profile Implementation"],
    urls: ["https://tomee.apache.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Apache_TomEE",
    type: "technology"
  },
  {
    id: "85268cc4-9a1e-4b5f-8f58-5cb362053ad8",
    name: "Arquillian",
    urls: ["http://arquillian.org/"],
    type: "technology"
  },
  {
    id: "d3ebaf2f-b952-482c-a802-49c94d7c4872",
    name: "Arquillian Graphene",
    urls: ["http://arquillian.org/arquillian-graphene/"],
    type: "technology"
  },
  {
    id: "559fb1bf-2e16-4e7a-9794-289d9c77f9bf",
    name: "Arquillian Drone",
    urls: ["http://arquillian.org/arquillian-extension-drone/"],
    type: "technology"
  },
  {
    id: "ade0b691-1d17-4ad7-95ea-1f1ec48d4bcd",
    name: "Arquillian ShrinkWrap",
    aliases: ["ShrinkWrap"],
    urls: ["http://arquillian.org/modules/shrinkwrap-shrinkwrap/"],
    type: "technology"
  },
  {
    id: "60bd4527-a606-4180-8261-8f8d6fff0412",
    name: "AspectJ",
    urls: ["https://www.eclipse.org/aspectj/"],
    wikiUrl: "https://de.wikipedia.org/wiki/AspectJ",
    type: "technology"
  },
  {
    id: "39bdefbf-fb38-42ed-ac94-ce37e3e893d9",
    name: "Aspose Cells",
    urls: ["https://products.aspose.com/cells"],
    wikiUrl: "https://en.wikipedia.org/wiki/Aspose_API",
    type: "technology"
  },
  {
    id: "b1b491e4-1900-4d88-9b2b-cbd0e8439365",
    name: "Aspose PDF",
    urls: ["https://products.aspose.com/pdf"],
    type: "technology"
  },
  {
    id: "a58e5cbc-9a47-442d-ae6f-bfb8e66b902e",
    name: "Aspose Words",
    urls: ["https://products.aspose.com/words"],
    wikiUrl: "https://en.wikipedia.org/wiki/Aspose.Words",
    type: "technology"
  },
  {
    id: "2d79b4ff-a8bc-4902-91a1-112336eede51",
    name: "Atlassian Bitbucket",
    aliases: ["Bitbucket", "Stash"],
    urls: ["https://bitbucket.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Bitbucket",
    type: "technology"
  },
  {
    id: "7ca359b0-aa54-46b1-9080-9dd45225d4aa",
    name: "Atlassian Confluence",
    aliases: ["Confluence"],
    urls: ["https://www.atlassian.com/de/software/confluence"],
    wikiUrl: "https://de.wikipedia.org/wiki/Confluence_(Atlassian)",
    type: "technology"
  },
  {
    id: "53c75f04-5782-417d-b5a5-1a8128c5a5b8",
    name: "Atlassian Jira Cloud",
    aliases: ["Jira"],
    urls: ["https://www.atlassian.com/de/software/jira"],
    wikiUrl: "https://de.wikipedia.org/wiki/Jira_(Software)",
    type: "technology"
  },
  {
    id: "c5c25c56-4ec2-4ab7-b3c4-380365fd68b7",
    name: "Atlassian Jira Server",
    aliases: ["Jira"],
    urls: ["https://www.atlassian.com/de/software/jira"],
    wikiUrl: "https://de.wikipedia.org/wiki/Jira_(Software)",
    type: "technology"
  },
  {
    id: "18c0cd8e-8a0f-4221-ad78-90c6e54365e9",
    name: "axios",
    urls: ["https://axios-http.com/"],
    type: "technology"
  },
  {
    id: "48ad3061-4a22-4a10-b578-11fe4ca015f2",
    name: "BIND Nameserver",
    aliases: ["bind", "DNS Server"],
    urls: ["https://www.isc.org/bind/"],
    wikiUrl: "https://de.wikipedia.org/wiki/BIND",
    type: "technology"
  },
  {
    id: "4af91558-089e-47bb-80c9-059706ffbeef",
    name: "Bower",
    aliases: ["package manager"],
    urls: ["https://bower.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Bower_(Software)",
    type: "technology"
  },
  {
    id: "d909d9bb-677c-4223-957b-fd64832a6eee",
    name: "C#",
    urls: ["https://docs.microsoft.com/en-us/dotnet/csharp/"],
    wikiUrl: "https://de.wikipedia.org/wiki/C-Sharp",
    type: "technology"
  },
  {
    id: "13c68032-17d0-4bbc-8496-edd45b5e003e",
    name: "Clam AntiVirus",
    aliases: ["ClamAV", "clamd"],
    urls: ["https://www.clamav.net/"],
    wikiUrl: "https://de.wikipedia.org/wiki/ClamAV",
    type: "technology"
  },
  {
    id: "90540b8a-d85e-482e-880d-d207d043728e",
    name: "containerd",
    urls: ["https://containerd.io"],
    type: "technology"
  },
  {
    id: "a0724ef2-8ea0-439a-9c94-5cb4b3119486",
    name: "Contexts and Dependency Injection",
    aliases: ["CDI"],
    urls: ["https://docs.oracle.com/javaee/6/tutorial/doc/giwhl.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Contexts_and_Dependency_Injection",
    type: "technology"
  },
  {
    id: "b8d037e5-36a8-4118-b6d9-1e648b708cb7",
    name: "continuous delivery",
    urls: ["https://www.atlassian.com/continuous-delivery/continuous-deployment"],
    wikiUrl: "https://de.wikipedia.org/wiki/Continuous_Delivery",
    type: "technology"
  },
  {
    id: "419fe413-f3b1-473e-8e09-a579c11f9eb4",
    name: "continuous integration",
    urls: ["https://www.redhat.com/de/topics/devops/what-is-ci-cd"],
    wikiUrl: "https://en.wikipedia.org/wiki/Continuous_integration",
    type: "technology"
  },
  {
    id: "a04dea32-9878-4036-816f-a0a96f5a8eb9",
    name: "ControlsFX",
    urls: ["https://github.com/controlsfx/controlsfx", "https://controlsfx.bitbucket.io/"],
    type: "technology"
  },
  {
    id: "0fc87388-95c8-471e-a229-275814834ff3",
    name: "CSS",
    urls: ["https://www.w3.org/Style/CSS/specs.de.html", "https://css-tricks.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Cascading_Style_Sheets",
    type: "technology"
  },
  {
    id: "021dcb4a-0cd4-4654-a7b7-a8c2b0e30b51",
    name: "Cypher Query Language",
    urls: ["https://neo4j.com/developer/cypher/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Cypher_(Abfragesprache)",
    type: "technology"
  },
  {
    id: "934ff905-5735-4ec8-8082-bc2f5fe74de8",
    name: "Cypress",
    aliases: ["JavaScript End to End Testing Framework"],
    urls: ["https://www.cypress.io/"],
    type: "technology"
  },
  {
    id: "06aeb479-0d51-4d92-978a-df021a020c8f",
    name: "Cyrus IMAP",
    urls: ["https://www.cyrusimap.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Cyrus_(Server)",
    type: "technology"
  },
  {
    id: "8b771135-5775-4503-9ff9-542f6ebc40d1",
    name: "DansGuardian",
    urls: ["https://www.smoothwall.com"],
    wikiUrl: "https://en.wikipedia.org/wiki/DansGuardian",
    type: "technology"
  },
  {
    id: "896442b0-ffaa-44c3-8232-314c5253d9b6",
    name: "DbUnit",
    wikiUrl: "http://dbunit.sourceforge.net/",
    type: "technology"
  },
  {
    id: "03dd92ea-d146-4afd-8f4c-5bab2e525f27",
    name: "Debian GNU/Linux",
    aliases: ["Debian"],
    urls: ["https://www.debian.org"],
    wikiUrl: "https://de.wikipedia.org/wiki/Debian",
    type: "technology"
  },
  {
    id: "ac1d01df-27be-4bc8-936b-f48738b92be7",
    name: "DHCP daemon",
    aliases: ["dhcpd"],
    urls: ["https://www.isc.org/dhcp/"],
    wikiUrl: "https://en.wikipedia.org/wiki/DHCPD",
    type: "technology"
  },
  {
    id: "60383d91-b207-40a5-a08f-57ab75788ee2",
    name: "Docker",
    urls: ["https://www.docker.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Docker_(Software)",
    type: "technology"
  },
  {
    id: "ad28e5f8-d203-43e1-bfdc-7c29649a231b",
    name: "Drone",
    aliases: ["drone.io", "Continuous Integration and Continuous Delivery platform"],
    urls: ["https://drone.io/"],
    type: "technology"
  },
  {
    id: "76c5f05d-4d58-4f9c-ac4b-65415948db91",
    name: "EasyMock",
    urls: ["https://easymock.org"],
    wikiUrl: "https://de.wikipedia.org/wiki/EasyMock",
    type: "technology"
  },
  {
    id: "19ce6845-a317-4d68-a8d4-9b548b40ef2f",
    name: "Eclipse IDE",
    urls: ["https://www.eclipse.org/ide/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Eclipse_(IDE)",
    type: "technology"
  },
  {
    id: "7b834e25-d7c1-447f-bb25-2262812fe089",
    name: "EclipseLink",
    aliases: ["Eclipse Toplink", "Toplink"],
    urls: ["https://www.eclipse.org/eclipselink/"],
    wikiUrl: "https://de.wikipedia.org/wiki/TopLink",
    type: "technology"
  },
  {
    id: "217e5c5a-e668-48cf-8cf7-92720a0bb4bc",
    name: "Elasticsearch",
    urls: ["https://www.elastic.co/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Elasticsearch",
    type: "technology"
  },
  {
    id: "42bc64d4-b834-4f48-88ca-d885b4cf6f10",
    name: "Enterprise JavaBeans",
    aliases: ["EJB"],
    urls: ["https://www.oracle.com/technetwork/java/ejb-141389.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Enterprise_JavaBeans",
    type: "technology"
  },
  {
    id: "99b18421-4bec-4203-bb79-dfae1b944fd3",
    name: "Exim Internet Mailer",
    aliases: ["Exim"],
    urls: ["https://www.exim.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Exim",
    type: "technology"
  },
  {
    id: "4bbc1cef-0ddf-4a37-a8e2-2827cf185918",
    name: "Expression Language",
    aliases: ["EL"],
    urls: ["https://docs.oracle.com/javaee/6/tutorial/doc/gjddd.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Unified_Expression_Language",
    type: "technology"
  },
  {
    id: "aaa7ec8e-2d77-4d3a-b7c4-988a0a2bee2a",
    name: "Extensible Markup Language",
    aliases: ["XML"],
    urls: ["https://www.w3.org/TR/2006/REC-xml11-20060816/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Extensible_Markup_Language",
    type: "technology"
  },
  {
    id: "4c437fe3-9160-435d-9f03-dc5153bf47f2",
    name: "F#",
    urls: ["https://docs.microsoft.com/en-us/dotnet/fsharp/"],
    wikiUrl: "https://en.wikipedia.org/wiki/F_Sharp_(programming_language)",
    type: "technology"
  },
  {
    id: "d7da8e7c-fecf-4505-8332-68f4d06f5c8a",
    name: "Fedora",
    wikiUrl: "https://de.wikipedia.org/wiki/Fedora_(Linux-Distribution)",
    urls: [
      "https://getfedora.org/de/",
      "https://www.redhat.com/de/topics/linux/fedora-vs-red-hat-enterprise-linux"
    ],
    type: "technology"
  },
  {
    id: "ef7e9026-7261-4297-bba7-f1ef29d63790",
    name: "Figma",
    wikiUrl: "https://en.wikipedia.org/wiki/Figma_(software)",
    urls: [
      "https://www.figma.com/"
    ],
    type: "technology"
  },
  {
    id: "a70939fc-d011-4402-aa4d-c8a0539d2a91",
    name: "FindBugs™",
    urls: ["http://findbugs.sourceforge.net/"],
    wikiUrl: "https://de.wikipedia.org/wiki/FindBugs",
    type: "technology"
  },
  {
    id: "86cc95fa-80f8-47da-85b1-9796fbbf89be",
    name: "FXML",
    urls: ["https://docs.oracle.com/javafx/2/get_started/fxml_tutorial.htm"],
    wikiUrl: "https://en.wikipedia.org/wiki/FXML",
    type: "technology"
  },
  {
    id: "b2c1140f-bfea-47c5-b9a6-0a74d2f6dcfd",
    name: "Gatsby",
    aliases: ["gatsbyjs", "Static Site Renderer"],
    urls: ["https://www.gatsbyjs.org"],
    type: "technology"
  },
  {
    id: "4919cc81-8ddf-45b7-b9e8-1f11eea34e67",
    name: "Git",
    urls: ["https://git-scm.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Git",
    type: "technology"
  },
  {
    id: "ddc21d90-ee86-4621-a72a-574dc5b3c824",
    name: "Github",
    urls: ["https://github.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/GitHub",
    type: "technology"
  },
  {
    id: "ab8c6ac7-de2c-4b1d-8a85-4272a2d531b2",
    name: "Glassfish",
    urls: ["https://javaee.github.io/glassfish/"],
    wikiUrl: "https://de.wikipedia.org/wiki/GlassFish",
    type: "technology"
  },
  {
    id: "002d5108-4aef-4ea8-9ab6-ad5a477b7c15",
    name: "GNU Privacy Guard",
    urls: ["https://gnupg.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/GNU_Privacy_Guard",
    type: "technology"
  },
  {
    id: "22694e42-a43d-4ee9-82e5-7877a984b1da",
    name: "Google Analytics",
    wikiUrl: "https://de.wikipedia.org/wiki/Google_Analytics",
    urls: ["https://marketingplatform.google.com/about/analytics/"],
    type: "technology"
  },
  {
    id: "3f40934b-22a2-4879-a003-daeb442db924",
    name: "Google Guava",
    urls: ["https://github.com/google/guava"],
    wikiUrl: "https://de.wikipedia.org/wiki/Google_Guava",
    type: "technology"
  },
  {
    id: "6a4d72e1-4355-4f86-ac41-75ec0f4047be",
    name: "GraalVM",
    urls: ["https://www.graalvm.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/GraalVM",
    type: "technology"
  },
  {
    id: "facc5ef2-f3ca-4818-be7e-e8e808c70904",
    name: "Gradle Build Tool",
    urls: ["https://gradle.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Gradle",
    type: "technology"
  },
  {
    id: "547efee1-a88b-4008-bf35-7d742aa34598",
    name: "GraphQL",
    urls: ["https://graphql.org/", "https://www.howtographql.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/GraphQL",
    type: "technology"
  },
  {
    id: "4ea7715c-e6db-4380-81db-91bd8d81ed8c",
    name: "Gremlin Graph Traversal Machine and Language",
    aliases: ["Gremlin Graph Query Language"],
    urls: ["https://arxiv.org/abs/1508.03843"],
    wikiUrl: "https://en.wikipedia.org/wiki/Gremlin_(query_language)",
    type: "technology"
  },
  {
    id: "214a1c0e-26b6-4016-8e25-a18a9afd46c5",
    name: "Gulp",
    aliases: ["gulpjs", "workflow automation and enhancement"],
    urls: ["https://gulpjs.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Gulp.js",
    type: "technology"
  },
  {
    id: "a835acf9-9cba-4bed-b7f6-56b16e6a86a1",
    name: "H2 Database Engine",
    aliases: ["H2"],
    urls: ["http://www.h2database.com/html/main.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/H2_Database",
    type: "technology"
  },
  {
    id: "88f4e7e3-2d3c-4521-8240-41da7c735055",
    name: "Hamcrest",
    urls: ["http://hamcrest.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Hamcrest",
    type: "technology"
  },
  {
    id: "0a9114d5-c5bd-47c0-8533-a27fa228a2f1",
    name: "Hibernate",
    urls: ["https://hibernate.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Hibernate_(Framework)",
    type: "technology"
  },
  {
    id: "6fa04dd0-1aab-4bb7-b689-eae075b1d2c6",
    name: "Hibernate Query Language",
    aliases: ["HQL"],
    urls: ["https://docs.jboss.org/hibernate/core/3.3/reference/en/html/queryhql.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Hibernate_(Framework)",
    type: "technology"
  },
  {
    id: "8bca58c9-d94e-4862-90dd-2462c199ff52",
    name: "HP Application Lifecycle Management",
    aliases: ["HP ALM"],
    wikiUrl: "https://en.wikipedia.org/wiki/Micro_Focus_Application_Lifecycle_Management",
    type: "technology"
  },
  {
    id: "e6336288-4d5b-4aa5-884d-be0fddb14edc",
    name: "HTML",
    aliases: ["HTML"],
    urls: ["https://www.w3.org/TR/html52/"],
    wikiUrl: "https://de.wikipedia.org/wiki/HTML5",
    type: "technology"
  },
  {
    id: "674e554a-4deb-41fa-8d5f-993f31b3f723",
    name: "HTML5",
    aliases: ["HTML"],
    urls: ["https://www.w3.org/TR/html52/"],
    wikiUrl: "https://de.wikipedia.org/wiki/HTML5",
    type: "technology"
  },
  {
    id: "ce9b2fbc-33b8-4d5e-a0d5-4c310498e269",
    name: "IBM WebSphere Application Server",
    aliases: ["WSA"],
    urls: ["https://www.ibm.com/de-de/marketplace/java-ee-runtime"],
    wikiUrl: "https://de.wikipedia.org/wiki/WebSphere",
    type: "technology"
  },
  {
    id: "d3a39e82-00d6-4a98-bc10-844e6a95f958",
    name: "Informatica PowerCenter",
    aliases: ["ETL"],
    urls: ["https://www.informatica.com/ch/de/products/data-integration/powercenter.html"],
    type: "technology"
  },
  {
    id: "bba3532d-5410-4ce0-9622-c4f913321669",
    name: "WildFly Application Server",
    urls: ["https://wildfly.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/WildFly",
    type: "technology"
  },
  {
    id: "4295a9e7-3253-4ac3-88c0-ab77b0bf5e0e",
    name: "JetBrains AppCode",
    urls: ["https://www.jetbrains.com/de-de/objc/"],
    wikiUrl: "https://de.wikipedia.org/wiki/AppCode",
    type: "technology"
  },
  {
    id: "3a28ef6b-77b6-44ad-978a-eaf662a2a703",
    name: "JetBrains IntelliJ IDEA",
    urls: ["https://www.jetbrains.com/de-de/idea/"],
    wikiUrl: "https://de.wikipedia.org/wiki/IntelliJ_IDEA",
    type: "technology"
  },
  {
    id: "b9ff7d6a-f3e9-4d56-b0a2-bd53e6f50188",
    name: "JetBrains Webstorm IDE",
    urls: ["https://www.jetbrains.com/de-de/webstorm/"],
    wikiUrl: "https://de.wikipedia.org/wiki/WebStorm",
    type: "technology"
  },
  {
    id: "3e0dc387-eeab-42b8-9c91-0bd6525b5b7b",
    name: "iptables",
    urls: ["http://netfilter.org/projects/iptables/index.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Iptables",
    type: "technology"
  },
  {
    id: "ec468bd6-1ca3-4a82-aa08-ce5f4a964f9b",
    name: "iOS",
    wikiUrl: "https://de.wikipedia.org/wiki/IOS_(Betriebssystem)",
    type: "technology"
  },
  {
    id: "4e1a2dd6-1c14-4545-bd48-c4d5819442e9",
    name: "Jamstack",
    wikiUrl: "",
    urls: ["https://en.wikipedia.org/wiki/Netlify#Jamstack"],
    type: "technology"
  },
  {
    id: "22336b76-1bec-41ad-9291-8a5200c0819f",
    name: "Jasmine Modultest-Bibliothek",
    aliases: ["Jasmine"],
    urls: ["https://jasmine.github.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Jasmine_(JavaScript)",
    type: "technology"
  },
  {
    id: "2cb9e317-7fb3-420a-a53c-128b47ea726b",
    name: "Java",
    urls: ["https://www.java.com/de/about/whatis_java.jsp"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "785764c6-2d4b-4c7b-82fd-c8da9b9f5f72",
    name: "JavaFX",
    urls: ["https://www.oracle.com/java/technologies/javase/javafx-docs.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaFX",
    type: "technology"
  },
  {
    id: "488be4b3-967f-4d45-bc11-c79e0b9f1390",
    name: "JavaFX Scene Builder",
    urls: ["https://www.oracle.com/java/technologies/javase/javafxscenebuilder-info.html"],
    type: "technology"
  },
  {
    id: "c77e0582-2f65-41dc-86e9-181cc12b853f",
    name: "Java 1.6",
    urls: ["https://www.oracle.com/java/technologies/javase-java-archive-javase6-downloads.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "ffb52cd4-0cd2-45f3-b11f-2b0c4c8158cf",
    name: "Java 1.7",
    urls: ["https://www.oracle.com/java/technologies/javase/javase7-archive-downloads.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "1bd30fff-a64c-42a7-b1e9-4fe05bc5419e",
    name: "Java 1.8",
    urls: ["https://docs.oracle.com/javase/8/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "540f8a19-888e-4a78-993c-cad0d67c8a34",
    name: "Java API for JSON Processing",
    aliases: ["JSON-P", "JSR 374 Specification"],
    urls: ["https://www.oracle.com/technical-resources/articles/java/json.html", "https://javaee.github.io/jsonp/"],
    type: "technology"
  },
  {
    id: "c688ac10-b1ea-4f32-9f56-146e00c3e558",
    name: "Java API for RESTful Web Services",
    aliases: ["JAX-RS"],
    urls: ["https://docs.oracle.com/javaee/7/tutorial/jaxrs.htm"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_API_for_RESTful_Web_Services",
    type: "technology"
  },
  {
    id: "5b6ec6c2-0317-4865-9c08-0f36d5d96bdc",
    name: "Java API for WebSocket",
    urls: ["https://docs.oracle.com/javaee/7/tutorial/websocket.htm"],
    wikiUrl: "https://de.wikipedia.org/wiki/WebSocket",
    type: "technology"
  },
  {
    id: "6a70dca7-c8ef-44ae-a8ac-7a2972a27aff",
    name: "Java API for XML Web Services",
    aliases: ["JAX-WS"],
    urls: ["https://javaee.github.io/metro-jax-ws/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_API_for_XML_Web_Services",
    type: "technology"
  },
  {
    id: "d4966824-7feb-4761-965a-4ad31fa1f5f7",
    name: "Java Authentication and Authorization Service",
    aliases: ["JAAS"],
    urls: ["https://docs.oracle.com/javase/8/docs/technotes/guides/security/jaas/JAASRefGuide.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_Authentication_and_Authorization_Service",
    type: "technology"
  },
  {
    id: "310c2a1f-c86f-4971-a82c-08ce5335a13a",
    name: "Java Bean Validation",
    aliases: ["JSR 303"],
    urls: ["https://docs.oracle.com/javaee/6/tutorial/doc/gircz.html"],
    wikiUrl: "https://en.wikipedia.org/wiki/Bean_Validation",
    type: "technology"
  },
  {
    id: "79c6fb8d-7446-49c9-8621-10b66e29ee4f",
    name: "JavaMail API",
    urls: ["https://www.oracle.com/java/technologies/javamail.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaMail",
    type: "technology"
  },
  {
    id: "f14fadfe-9dcc-4d36-8799-d49d21a7bbd7",
    name: "Java Message Service",
    aliases: ["JMS"],
    urls: ["https://www.oracle.com/java/technologies/java-message-service.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_Message_Service",
    type: "technology"
  },
  {
    id: "a401637d-ad5f-4a50-abea-69c0647a929d",
    name: "Java Named Queries",
    aliases: ["JPA Named Queries"],
    urls: ["https://docs.oracle.com/cd/E16439_01/doc.1013/e13981/ent30qry001.htm"],
    type: "technology"
  },
  {
    id: "404a8e13-3dc5-41ad-9158-6f5ebc44005d",
    name: "Java Persistence API",
    aliases: ["JPA"],
    urls: ["https://www.oracle.com/java/technologies/persistence-jsp.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_Persistence_API",
    type: "technology"
  },
  {
    id: "c774a0d5-a448-4ec1-a566-7be02dd33808",
    name: "Java Persistence Criteria API",
    urls: ["https://docs.oracle.com/javaee/6/tutorial/doc/gjitv.html"],
    wikiUrl: "https://en.wikibooks.org/wiki/Java_Persistence/Criteria",
    type: "technology"
  },
  {
    id: "8a9cc04a-3dd3-454d-8839-91440b4fe68c",
    name: "Java Persistence Query Language",
    aliases: ["JPQL", "Jakarta Persistence Query Language"],
    urls: ["https://docs.oracle.com/middleware/1221/toplink/jpa-extensions-reference/jpql.htm#TLJPA630"],
    wikiUrl: "https://en.wikipedia.org/wiki/Java_Persistence_Query_Language",
    type: "technology"
  },
  {
    id: "406f8ef4-2801-46a0-9bac-a2765d3694d8",
    name: "Java Platform, Enterprise Edition",
    aliases: ["JEE"],
    urls: ["https://www.oracle.com/java/technologies/java-ee-glance.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_Platform,_Enterprise_Edition",
    type: "technology"
  },
  {
    id: "7a52c9dc-2680-4295-a278-0e3283071b98",
    name: "Java Server Faces",
    aliases: ["JSF"],
    urls: ["https://www.oracle.com/java/technologies/javaserverfaces.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaServer_Faces",
    type: "technology"
  },
  {
    id: "effc8353-d177-4981-93f2-87453bf37953",
    name: "JavaServer Pages",
    aliases: ["JSP", "Jakarta Server Pages"],
    urls: ["https://www.oracle.com/java/technologies/jspt.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaServer_Pages",
    type: "technology"
  },
  {
    id: "80046f4e-e9f0-4ce8-b47f-6acd517cde09",
    name: "Java Servlet",
    urls: ["https://docs.oracle.com/javaee/7/tutorial/servlets.htm"],
    wikiUrl: "https://de.wikipedia.org/wiki/Servlet",
    type: "technology"
  },
  {
    id: "ae5a6737-be21-4b88-9e5b-8b5f85538060",
    name: "Java Transaction API",
    aliases: ["JTA"],
    urls: ["https://www.oracle.com/java/technologies/jta.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Java_Transaction_API",
    type: "technology"
  },
  {
    id: "10729383-5c02-4de4-9ff9-d8976b4f8374",
    name: "JavaScript",
    aliases: ["ECMAScript", "ES"],
    urls: ["https://www.ecma-international.org/publications/standards/Ecma-262.htm"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaScript",
    type: "technology"
  },
  {
    id: "5caf9fef-8ab9-4560-8aeb-4026c3761d02",
    name: "JavaScript Object Notation",
    aliases: ["JSON"],
    urls: ["https://www.json.org/json-de.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/JavaScript_Object_Notation",
    type: "technology"
  },
  {
    id: "3777783f-d014-4439-a39c-f303047f825b",
    name: "JSON Schema",
    urls: ["https://json-schema.org/"],
    type: "technology"
  },
  {
    id: "9099d5c6-d046-4a30-b655-d752852b3ac3",
    name: "Jenkins",
    aliases: ["Hudson"],
    urls: ["https://www.jenkins.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Jenkins_(Software)",
    type: "technology"
  },
  {
    id: "1636824b-3daf-4b42-83d6-222307dc8551",
    name: "Jenkins Pipeline",
    urls: ["https://www.jenkins.io/doc/book/pipeline/"],
    type: "technology"
  },
  {
    id: "a1666796-df50-4d42-8199-f1bb3dfdd8c1",
    name: "Jest",
    aliases: ["JavaScript testing framework"],
    urls: ["https://jestjs.io/"],
    type: "technology"
  },
  {
    id: "2ab6eb2d-f4d5-45b0-a9d5-c88af0c9cdbc",
    name: "JRebel",
    urls: ["https://www.jrebel.com/products/jrebel"],
    wikiUrl: "https://de.wikipedia.org/wiki/JRebel",
    type: "technology"
  },
  {
    id: "9f8dd064-c3c0-4a37-bd07-2d007f0becd7",
    name: "JSON Web Token",
    aliases: ["JWT"],
    urls: ["https://tools.ietf.org/html/rfc7519", "https://jwt.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/JSON_Web_Token",
    type: "technology"
  },
  {
    id: "0f54c041-74b4-42d0-93a2-2b2fd8b6172f",
    name: "JSX",
    urls: ["https://reactjs.org/docs/introducing-jsx.html"],
    type: "technology"
  },
  {
    id: "7d4a3e25-170f-4d03-a268-4347897c0f4e",
    name: "jQuery",
    urls: ["https://jquery.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/JQuery",
    type: "technology"
  },
  {
    id: "85e9637c-c69e-4048-b652-04cd947a0629",
    name: "JUnit",
    urls: ["https://junit.org/junit5/"],
    wikiUrl: "https://de.wikipedia.org/wiki/JUnit",
    type: "technology"
  },
  {
    id: "cdc5f954-4440-4d9f-881c-9bc4dc6c48df",
    name: "JWT RBAC for MicroProfile",
    urls: ["https://github.com/eclipse/microprofile-jwt-auth"],
    type: "technology"
  },
  {
    id: "90605308-e856-438d-830f-9526b8d7de81",
    name: "Karma Test Runner",
    aliases: ["Karma"],
    urls: ["https://karma-runner.github.io/"],
    wikiUrl: "https://github.com/karma-runner/karma",
    type: "technology"
  },
  {
    id: "2079c402-db03-4252-9390-fa781f63f756",
    name: "Kerberos",
    wikiUrl: "https://de.wikipedia.org/wiki/Kerberos_(Protokoll)",
    type: "technology"
  },
  {
    id: "cd1c526f-7af8-4bee-9761-fde0be5645e4",
    name: "Kotlin",
    urls: ["https://kotlinlang.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Kotlin_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "2ea82c55-70c5-43e4-bc28-2804eeb7e193",
    name: "Kubernetes",
    aliases: ["k8s"],
    urls: ["https://kubernetes.io/de/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Kubernetes",
    type: "technology"
  },
  {
    id: "4e84e8cd-76de-4ff3-b557-cfbcc2851ada",
    name: "Latex",
    wikiUrl: "https://de.wikipedia.org/wiki/LaTeX",
    urls: ["https://www.latex-project.org/"],
    type: "technology"
  },
  {
    id: "15ebed4a-4d14-4cb8-9316-7a8abe418f26",
    name: "Lint",
    wikiUrl: "https://de.wikipedia.org/wiki/Lint_(Programmierwerkzeug)",
    type: "technology"
  },
  {
    id: "529f5627-e98e-496f-9fa1-96eaeff7da47",
    name: "LINQ",
    aliases: ["Language Integrated Query"],
    urls: ["https://docs.microsoft.com/en-us/dotnet/csharp/programming-guide/concepts/linq/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Language_Integrated_Query",
    type: "technology"
  },
  {
    id: "d49f2580-bb1c-44b2-a318-1c2f204f3706",
    name: "Liquibase",
    aliases: ["Database Refactoring"],
    urls: ["https://www.liquibase.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Liquibase",
    type: "technology"
  },
  {
    id: "b6a688f5-0755-4111-965b-38ba85cfaef3",
    name: "Material Design",
    urls: ["https://material.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Material_Design",
    type: "technology"
  },
  {
    id: "3e6031db-ac3a-4db9-a9eb-a04e112ab7a9",
    name: "MacOS",
    urls: ["https://www.apple.com/chde/macos/what-is/"],
    wikiUrl: "https://de.wikipedia.org/wiki/MacOS",
    type: "technology"
  },
  {
    id: "42c4e807-8a34-488a-b9ca-2ff52c67b28a",
    name: "MicroProfile",
    urls: ["https://github.com/eclipse/microprofile"],
    type: "technology"
  },
  {
    id: "d3313314-2bd5-4e3b-81e4-7f4bbf3dea22",
    name: "MicroProfile Fault Tolerance",
    urls: ["https://github.com/eclipse/microprofile-fault-tolerance"],
    type: "technology"
  },
  {
    id: "1058757c-0fc2-445e-82da-75e45175b7d5",
    name: "MicroProfile Health",
    urls: ["https://github.com/eclipse/microprofile-health"],
    type: "technology"
  },
  {
    id: "f668a2ca-0606-4d92-b850-851a04a71036",
    name: "MicroProfile OpenTracing",
    urls: ["https://github.com/eclipse/microprofile-opentracing"],
    type: "technology"
  },
  {
    id: "dedfa1cc-106e-435a-a758-03842ed6f34d",
    name: "MicroProfile Service Mesh",
    urls: ["https://github.com/eclipse/microprofile-service-mesh"],
    type: "technology"
  },
  {
    id: "4747773f-dfa3-4c26-a303-05d304b8d4c0",
    name: "Microsoft Azure Cloud Computing Services",
    aliases: ["Microsoft Azure"],
    urls: ["https://azure.microsoft.com/de-de/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Azure",
    type: "technology"
  },
  {
    id: "fe090153-3c22-430e-921f-a84eea3df65b",
    name: "MIT Kerberos-Server\n",
    urls: [
      "https://web.mit.edu/kerberos/www/krb5-latest/doc/admin/appl_servers.html",
      "https://web.mit.edu/kerberos/"
    ],
    type: "technology"
  },
  {
    id: "a602416f-aff1-42e4-9ebb-ec4822bbfabf",
    name: "Bicep",
    urls: ["https://docs.microsoft.com/en-us/azure/azure-resource-manager/bicep/overview", "https://github.com/Azure/bicep"],
    type: "technology"
  },
  {
    id: "431ba733-b670-44e7-93fd-a3abbd5f8367",
    name: "Azure Active Directory",
    aliases: ["Azure AD"],
    urls: ["https://azure.microsoft.com/de-de/services/active-directory/", "https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-whatis"],
    type: "technology"
  },
  {
    id: "6c104398-165d-4b51-bd9f-27bd476dcd7a",
    name: "Azure Active Directory B2C",
    aliases: ["Azure AD B2C"],
    urls: ["https://azure.microsoft.com/de-de/services/active-directory/external-identities/b2c/#overview"],
    type: "technology"
  },
  {
    id: "ad9939bf-06f4-43c7-a399-4cd9df847721",
    name: "Log Analytics workspace",
    type: "technology"
  },
  {
    id: "d12cb8f8-b3e0-428b-b846-7ba071112c7f",
    name: "Azure API Management",
    urls: ["https://azure.microsoft.com/en-us/services/api-management/#overview"],
    type: "technology"
  },
  {
    id: "fee15128-62d6-41fc-bc40-438872850267",
    name: "Azure App Configuration",
    aliases: {
      de: ["Schneller, skalierbarer Parameterspeicher zur App-Konfiguration"],
      en: ["Fast, scalable parameter storage for app configuration"]
    },
    urls: ["https://azure.microsoft.com/en-us/services/app-configuration/"],
    type: "technology"
  },
  {
    id: "6136db96-5c55-437a-a253-71f6d47ef56f",
    name: "Application Insights",
    type: "technology"
  },
  {
    id: "61dc691e-b221-481c-8a16-b88a004a4743",
    name: "Azure Artifacts",
    type: "technology"
  },
  {
    id: "0a6af6c6-0f02-4e21-a00b-328df23c2faf",
    name: "Azure Container Apps",
    urls: ["https://azure.microsoft.com/en-us/services/container-apps"],
    type: "technology"
  },
  {
    id: "76f24268-9ccb-4d63-98b6-dbeb05251f20",
    name: "Azure Container Instances",
    aliases: ["ACI"],
    urls: ["https://azure.microsoft.com/en-us/services/container-instances/"],
    type: "technology"
  },
  {
    id: "5b2c6956-226d-4eed-9962-ec5b96256d77",
    name: "Azure Container Registry",
    aliases: ["ACR", "Docker registry service"],
    urls: ["https://azure.microsoft.com/en-us/services/container-registry"],
    type: "technology"
  },
  {
    id: "41f0309a-242b-4ee2-90df-bd90d3b11713",
    name: "Azure Cosmos DB",
    urls: ["https://azure.microsoft.com/de-de/services/cosmos-db/"],
    type: "technology"
  },
  {
    id: "c8409eae-9401-49da-8ab2-96752f1b71af",
    name: "Azure DevOps Services",
    urls: ["https://azure.microsoft.com/de-de/services/devops/"],
    type: "technology"
  },
  {
    id: "f4ac4c81-2258-4ade-89c9-ef74a214db49",
    name: "Azure Durable Function Apps",
    urls: ["https://docs.microsoft.com/en-us/azure/azure-functions/durable/durable-functions-overview"],
    type: "technology"
  },
  {
    id: "7a6bd934-b1df-4446-89f6-e7c566a7b212",
    name: "Azure Enterprise Integration Platform as a Service",
    aliases: ["iPAAS"],
    urls: ["https://azure.microsoft.com/en-en/product-categories/integration/"],
    type: "technology"
  },
  {
    id: "340b0a7f-91c7-443c-9112-36b8c6848815",
    name: "Azure Event Grid",
    urls: ["https://azure.microsoft.com/de-de/services/event-grid/"],
    type: "technology"
  },
  {
    id: "d8f38ac3-afc7-4cd4-b111-1ff31c861ff1",
    name: "Azure Function Apps",
    urls: ["https://azure.microsoft.com/de-de/services/functions/"],
    type: "technology"
  },
  {
    id: "a1a3a90c-a82c-4d05-9df4-d3c745748677",
    name: "Azure Functions Core Tools",
    urls: ["https://github.com/Azure/azure-functions-core-tools#readme"],
    type: "technology"
  },
  {
    id: "02dc2c16-bb75-4f4c-a653-23e22b77e7d5",
    name: "Azure Key Vault",
    urls: ["https://azure.microsoft.com/en-us/services/key-vault/"],
    type: "technology"
  },
  {
    id: "9c260564-c5fd-4e02-bf31-90abf6b199a8",
    name: "Azure Monitor",
    urls: ["https://azure.microsoft.com/de-de/services/monitor/#overview"],
    type: "technology"
  },
  {
    id: "85499e3f-6968-4050-b9d1-0a55af729995",
    name: "Azure Pipelines",
    aliases: ["Build, test and deploy with CI/CD"],
    urls: ["https://azure.microsoft.com/en-us/services/devops"],
    type: "technology"
  },
  {
    id: "49d1b0d7-c9ba-49c7-9119-abf3415dc29f",
    name: "Azure Repos",
    aliases: ["cloud-hosted private Git repositories"],
    type: "technology"
  },
  {
    id: "faba5689-d389-455a-92b8-6be3efef7e38",
    name: "Azure Resource Manager",
    aliases: ["ARM"],
    urls: ["https://azure.microsoft.com/de-de/features/resource-manager/"],
    type: "technology"
  },
  {
    id: "dbb2da6e-40ab-461a-b0c5-76b66709bda3",
    name: "Azure Resource Manager templates",
    aliases: ["ARM templates"],
    urls: ["https://docs.microsoft.com/en-us/azure/azure-resource-manager/templates/"],
    type: "technology"
  },
  {
    id: "57574a4d-4edf-46fc-8a3c-e5c811885906",
    name: "Azure Service Bus",
    urls: ["https://azure.microsoft.com/de-de/services/service-bus/"],
    type: "technology"
  },
  {
    id: "3870dfd2-75af-4026-8539-62adee0dea98",
    name: "Azure Static Web Apps",
    urls: ["https://azure.microsoft.com/en-us/services/app-service/static/#overview"],
    type: "technology"
  },
  {
    id: "baf1b380-9fa3-4bef-95b0-760f25c9de6e",
    name: "Azure Storage account",
    type: "technology"
  },
  {
    id: "b9b8ab71-ff03-490a-8d47-8d99d2216b97",
    name: "Azure SQL Database",
    urls: ["https://azure.microsoft.com/en-us/products/azure-sql/database/"],
    type: "technology"
  },
  {
    id: "30e143c6-9810-4495-b72f-e85b441f063e",
    name: "Azure Web Apps",
    urls: ["https://azure.microsoft.com/en-us/services/app-service/web/"],
    type: "technology"
  },
  {
    id: "84971ac5-d0aa-4447-b7b4-652bf79abb55",
    name: "Identity and access management",
    aliases: ["IAM"],
    urls: ["https://azure.microsoft.com/en-us/product-categories/identity/"],
    type: "technology"
  },
  {
    id: "c6311bc6-25f1-4d4e-a4ed-4fb2aa9064dc",
    name: "mande",
    urls: ["https://github.com/posva/mande"],
    type: "technology"
  },
  {
    id: "3e6c6c62-189c-4c15-9da0-59a7a0cd4b6f",
    name: "Microsoft Cloud Adoption Framework für Azure",
    urls: ["https://docs.microsoft.com/en-us/azure/cloud-adoption-framework/"],
    type: "technology"
  },
  {
    id: "3d2c31fc-b096-41ec-9d59-dbb750dace58",
    name: "Microsoft Authentication Library",
    aliases: ["MSAL"],
    urls: ["https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-overview"],
    type: "technology"
  },
  {
    id: "c4fb6a94-23d3-46e7-a791-163edb6af9ec",
    name: "Microsoft identity platform",
    urls: ["https://www.microsoft.com/en-us/security/business/identity-access/azure-active-directory-microsoft-identity-management-platform"],
    type: "technology"
  },
  {
    id: "45e85f59-d875-4d6f-914b-a28f8ad96051",
    name: "Microsoft Entra",
    aliases: ["Secure access for a connected world"],
    urls: ["https://www.microsoft.com/en-us/security/business/microsoft-entra"],
    type: "technology"
  },
  {
    id: "08bea2ad-733c-4c44-a8e5-8e2d3b1a0c3a",
    name: "Microsoft Excel",
    urls: ["https://www.microsoft.com/de-ch/microsoft-365/excel"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Excel",
    type: "technology"
  },
  {
    id: "2e926952-ed44-4ec3-96a3-0671675a84a0",
    name: "Microsoft Graph",
    urls: ["https://docs.microsoft.com/en-us/graph/overview"],
    type: "technology"
  },
  {
    id: "9f15473b-a6dc-493a-b394-bead2b1cc1e3",
    name: "Microsoft PowerPoint",
    urls: ["https://www.microsoft.com/de-de/microsoft-365/powerpoint"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_PowerPoint",
    type: "technology"
  },
  {
    id: "52a30cc9-3a9e-4a1b-ab45-75edaabc26e5",
    name: "Microsoft Project",
    urls: ["https://www.microsoft.com/de-ch/microsoft-365/project/project-management"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Project",
    type: "technology"
  },
  {
    id: "58e6efa3-9c09-4364-b853-7c458b5d37c7",
    name: "Microsoft SQL Server",
    aliases: ["MS SQL Server"],
    urls: ["https://www.microsoft.com/de-de/sql-server"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_SQL_Server",
    type: "technology"
  },
  {
    id: "c15ab6f5-be32-472f-b714-f93fb7c8ee44",
    name: "Microsoft Visio Professional",
    urls: ["https://www.microsoft.com/de-ch/microsoft-365/p/visio-professional-2019/cfq7ttc0k7cg?activetab=pivot%3aoverviewtab"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Visio",
    type: "technology"
  },
  {
    id: "20da4500-c8e0-4395-9c12-87150827819d",
    name: "Microsoft Visual FoxPro",
    aliases: ["VFP"],
    urls: ["https://docs.microsoft.com/en-us/previous-versions/visualstudio/foxpro/mt490117(v=msdn.10)?redirectedfrom=MSDN"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Visual_FoxPro",
    type: "technology"
  },
  {
    id: "cffcf501-2c71-4b62-8b56-f64df920ed9f",
    name: "Microsoft Visual Studio Code",
    aliases: ["VS Code"],
    urls: ["https://code.visualstudio.com/"],
    type: "technology"
  },
  {
    id: "68ba37d8-1349-443e-9c44-1ff75b3a70ce",
    name: "Microsoft Windows 10",
    aliases: ["Windows 10"],
    urls: ["https://www.microsoft.com/de-ch/windows/get-windows-10"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Windows_10",
    type: "technology"
  },
  {
    id: "1da32bfe-9997-4276-af44-a89d4c81f3e8",
    name: "Microsoft Word",
    urls: ["https://www.microsoft.com/de-de/microsoft-365/word"],
    wikiUrl: "https://de.wikipedia.org/wiki/Microsoft_Word",
    type: "technology"
  },
  {
    id: "b15ffdd4-75ac-4ce3-b7ef-872600ca1948",
    name: "Mockito",
    urls: ["https://site.mockito.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Mockito",
    type: "technology"
  },
  {
    id: "a4794f5e-d17b-48f4-83a1-c695a216243e",
    name: "Moodle",
    urls: ["https://moodle.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Moodle",
    type: "technology"
  },
  {
    id: "c5c4035f-39ed-4fbd-b75b-0d1d6fddc72a",
    name: "MongoDB",
    urls: ["https://www.mongodb.com/"],
    wikiUrl: "https://en.wikipedia.org/wiki/MongoDB",
    type: "technology"
  },
  {
    id: "81447678-22d2-4e66-b20a-0b6e1dc8629e",
    name: "MySQL",
    urls: ["https://www.mysql.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/MySQL",
    type: "technology"
  },
  {
    id: "0e7ae9a7-857d-40a8-a165-5e98d48a30e6",
    name: "Nagios",
    aliases: [
      "The Industry Standard In IT Infrastructure Monitoring",
      "IT Infrastructure Monitoring"
    ],
    wikiUrl: "https://de.wikipedia.org/wiki/Nagios",
    urls: ["https://www.nagios.org/"],
    type: "technology"
  },
  {
    id: "13e7e3e8-86cf-454b-9777-fe7250103773",
    name: "Neo4j graph database",
    aliases: ["neo4j"],
    urls: ["https://neo4j.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Neo4j",
    type: "technology"
  },
  {
    id: "445df01b-9af2-483c-89b6-dd03a3c62d55",
    name: ".NET 6",
    aliases: [".NET Core"],
    urls: ["https://dotnet.microsoft.com/", "https://docs.microsoft.com/en-us/dotnet/"],
    wikiUrl: "https://de.wikipedia.org/wiki/.NET_Core",
    type: "technology"
  },
  {
    id: "6f4f97b0-8f72-487c-be26-3d8e864d5c32",
    name: "Netbeans IDE",
    aliases: ["Netbeans"],
    urls: ["https://netbeans.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/NetBeans_IDE",
    type: "technology"
  },
  {
    id: "48db98b4-79f1-48fd-97e8-ae6c9917725d",
    name: "netlify",
    urls: ["https://www.netlify.com/"],
    type: "technology"
  },
  {
    id: "e6a2b7b6-5f82-464e-8bd7-ebdd98511463",
    name: "NgRx",
    aliases: ["reactive state for angular"],
    urls: ["https://ngrx.io/"],
    type: "technology"
  },
  {
    id: "9aa0febd-fc7f-4cbc-86a6-bda2a2bf964a",
    name: "Network File System Deamon",
    aliases: ["nfs"],
    urls: ["https://tldp.org/LDP/nag/node143.html"],
    type: "technology"
  },
  {
    id: "c543460c-6971-4bf0-82e1-474c2c2cb1dd",
    name: "Node.js®",
    aliases: ["node"],
    urls: ["https://nodejs.org/de/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Node.js",
    type: "technology"
  },
  {
    id: "caad85de-9fd0-4aae-80c2-7d1b6653a9e8",
    name: "npm",
    urls: ["https://www.npmjs.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Npm_(Software)",
    type: "technology"
  },
  {
    id: "db85c91d-101b-452e-bced-baea5b2d8f66",
    name: "NuGet",
    urls: ["https://www.nuget.org/"],
    type: "technology"
  },
  {
    id: "95c8854f-521d-4219-b1e8-09044d6d20e7",
    name: "Network Time Protocol Deamon",
    aliases: ["ntpd"],
    urls: ["https://www.eecis.udel.edu/~mills/ntp/html/index.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Network_Time_Protocol",
    type: "technology"
  },
  {
    id: "e97d92bd-bf2c-4d8e-9eaf-8480cc25b50e",
    name: "Omada Identity Suite",
    urls: ["https://www.omada.net/de-de/losungen/omada-identity-suite"],
    wikiUrl: "https://de.wikipedia.org/wiki/Identit%C3%A4tsmanagement",
    type: "technology"
  },
  {
    id: "c7947cd7-3697-409a-b7d5-f1ee9eb96d60",
    name: "OmniGraffle",
    urls: ["https://www.omnigroup.com/omnigraffle/"],
    wikiUrl: "https://en.wikipedia.org/wiki/OmniGraffle",
    type: "technology"
  },
  {
    id: "e2d543cb-1dbd-42f8-92f3-b5ad2b2498e6",
    name: "OpenAPI",
    urls: ["https://www.openapis.org/", "https://swagger.io/specification/", "https://spec.openapis.org/oas/latest.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/OpenAPI",
    type: "technology"
  },
  {
    id: "dd7c5c9f-7730-4b8c-9220-21032866d540",
    name: "Open Graph protocol",
    urls: ["https://ogp.me"],
    wikiUrl: "https://de.wikipedia.org/wiki/Facebook#Open_Graph",
    type: "technology"
  },
  {
    id: "ca0b80b3-4d55-4b4b-a306-7ce928d2c1ab",
    name: "OpenLDAP",
    aliases: ["LDAP"],
    urls: ["https://www.openldap.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/OpenLDAP",
    type: "technology"
  },
  {
    id: "1831e201-4d77-4e51-8770-c02e403462c6",
    name: "Oracle Application Express",
    aliases: ["APEX"],
    urls: ["https://apex.oracle.com/de/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Oracle_Application_Express",
    type: "technology"
  },
  {
    id: "775d977f-8d14-490f-81d6-b81a51656c5f",
    name: "Oracle Business Intelligence Enterprise Edition",
    aliases: ["OBIEE"],
    urls: ["https://www.oracle.com/middleware/technologies/bi-enterprise-edition.html"],
    wikiUrl: "https://en.wikipedia.org/wiki/Oracle_Business_Intelligence_Suite_Enterprise_Edition",
    type: "technology"
  },
  {
    id: "652384e7-2290-42ac-a182-95fcdbd43665",
    name: "Oracle Business Intelligence Publisher",
    aliases: ["Oracle BI Publisher"],
    urls: ["https://www.oracle.com/middleware/technologies/bi-publisher.html"],
    wikiUrl: "https://en.wikipedia.org/wiki/Oracle_BI_Publisher",
    type: "technology"
  },
  {
    id: "eade989d-bea5-492c-afba-684366cdf76e",
    name: "Oracle Database",
    aliases: ["Oracle Database Server", "Oracle RDBMS"],
    urls: ["https://www.oracle.com/index.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Oracle_(Datenbanksystem)",
    type: "technology"
  },
  {
    id: "22457c45-4b1b-4c9e-b998-ead64d6be530",
    name: "Oracle OLAP",
    aliases: ["OLAP"],
    urls: ["https://www.oracle.com/database/technologies/olap.html"],
    wikiUrl: "https://en.wikipedia.org/wiki/Oracle_OLAP",
    type: "technology"
  },
  {
    id: "dda2ebeb-7367-49d6-bfa5-acc306930a7a",
    name: "Oracle Service Bus",
    urls: ["https://www.oracle.com/middleware/technologies/service-bus.html"],
    type: "technology"
  },
  {
    id: "e2fafe25-4be4-446a-a296-039c5aa2a9e9",
    name: "Oracle SQL Developer",
    urls: ["https://www.oracle.com/database/technologies/appdev/sqldeveloper-landing.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/SQL_Developer",
    type: "technology"
  },
  {
    id: "02cfabc7-4b18-4122-abac-fe0310d5bee1",
    name: "Oracle WebLogic Server",
    aliases: ["WLS"],
    urls: ["oracle.com/ch-de/middleware/weblogic/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Oracle_WebLogic_Server",
    type: "technology"
  },
  {
    id: "3bbfb262-dd6d-492c-9a2c-19fd8e6badb0",
    name: "OSGI",
    urls: ["https://www.osgi.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/OSGi",
    type: "technology"
  },
  {
    id: "4d244567-3459-40a1-a8ac-6e55d9b739d8",
    name: "OpenBSD",
    wikiUrl: "https://de.wikipedia.org/wiki/OpenBSD",
    urls: [
      "https://www.openbsd.org/"
    ],
    type: "technology"
  },
  {
    id: "4c83f365-23f8-4cef-af8d-9d13c981bae1",
    name: "Open Technology Real Services",
    aliases: ["OTRS", "Open Ticket Request System"],
    urls: ["https://otrs.com/de/home/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Open_Technology_Real_Services",
    type: "technology"
  },
  {
    id: "b39835be-3ef7-4b71-8778-05bec06901f6",
    name: "Pinia",
    urls: ["https://pinia.vuejs.org/"],
    type: "technology"
  },
  {
    id: "94d709d6-0ae2-4dd2-928f-b1cb0b498c16",
    name: "Play Framework",
    urls: ["https://www.playframework.com/"],
    type: "technology"
  },
  {
    id: "8f24e68e-65ab-4bf2-bcfd-54a1fa4dfdd8",
    name: "Pluggable Authentication Modules",
    aliases: ["pam", "pamd"],
    urls: ["https://docs.ansible.com/ansible/latest/modules/pamd_module.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Pluggable_Authentication_Modules",
    type: "technology"
  },
  {
    id: "32f0c50e-6239-480b-af44-0108018ecbb7",
    name: "PL/SQL",
    urls: ["https://www.oracle.com/ch-de/database/technologies/appdev/plsql.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/PL/SQL",
    type: "technology"
  },
  {
    id: "feeb1bf2-c9a5-4bcc-af83-8de59057c8b6",
    name: "Perl",
    urls: ["https://www.perl.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Perl_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "de48bd67-432c-403c-83c6-9da73e0927c6",
    name: "PHP",
    urls: ["https://www.php.net/"],
    wikiUrl: "https://de.wikipedia.org/wiki/PHP",
    type: "technology"
  },
  {
    id: "b881fcfb-bede-425d-8041-1dbfa9e23bec",
    name: "ProFTPD",
    aliases: ["Highly configurable GPL-licensed FTP server software"],
    wikiUrl: "https://de.wikipedia.org/wiki/ProFTPD",
    urls: ["http://www.proftpd.org/"],
    type: "technology"
  },
  {
    id: "a4218084-dc1f-482a-bfd5-8d451ccf0f5a",
    name: "Python",
    urls: ["https://www.python.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Python_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "7cf4b017-bf20-4785-8cbd-294864681f02",
    name: "PyQT",
    urls: ["https://wiki.python.org/moin/PyQt"],
    wikiUrl: "https://en.wikipedia.org/wiki/PyQt",
    type: "technology"
  },
  {
    id: "e0437c39-cba2-4714-a0e3-7bff950710bb",
    name: "Postfix",
    aliases: ["Mail Transfer Agent", "MTA"],
    urls: ["http://www.postfix.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Postfix_(Mail_Transfer_Agent)",
    type: "technology"
  },
  {
    id: "14454175-d253-4382-8385-9daa11a5c453",
    name: "PostgreSQL",
    urls: ["https://www.postgresql.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/PostgreSQL",
    type: "technology"
  },
  {
    id: "8b016510-1dc7-4d79-a3b8-b24791e9336a",
    name: "Postman",
    urls: ["https://www.postman.com/"],
    type: "technology"
  },
  {
    id: "ef04bbae-2ea1-4bdc-9b48-1158b70a7b7b",
    name: "PowerMock",
    urls: ["https://github.com/powermock/powermock"],
    wikiUrl: "https://de.wikipedia.org/wiki/PowerMock",
    type: "technology"
  },
  {
    id: "f88f3d9b-d8f8-4661-92bd-8cdf800a2369",
    name: "Protractor end-to-end test framework",
    aliases: ["Protractor"],
    urls: ["https://www.protractortest.org/"],
    type: "technology"
  },
  {
    id: "cbb11f8e-4e7f-4e6e-bab1-bd6dfb4a5e66",
    name: "Prettyfaces",
    urls: ["https://www.ocpsoft.org/prettyfaces/"],
    type: "technology"
  },
  {
    id: "3981e884-8a5b-4b1b-b076-e8ee38b78e31",
    name: "Primefaces",
    urls: ["https://www.primefaces.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/PrimeFaces",
    type: "technology"
  },
  {
    id: "b3e0ce17-b0ee-4817-87a7-e44b0eef09ef",
    name: "QT",
    urls: ["https://www.qt.io/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Qt_(Bibliothek)",
    type: "technology"
  },
  {
    id: "ed2de5ce-a60d-4d99-8ad1-02a75985b537",
    name: "Quarkus",
    aliases: ["Supersonic Subatomic Java"],
    urls: ["https://quarkus.io/"],
    wikiUrl: "https://en.wikipedia.org/wiki/GraalVM",
    type: "technology"
  },
  {
    id: "550c6de2-8860-4558-8b96-1e3c0a481833",
    name: "Quasar",
    urls: ["https://github.com/puniverse/quasar"],
    type: "technology"
  },
  {
    id: "38134f92-f6e5-47dc-be76-e177891063a0",
    name: "free RADIUS",
    aliases: ["RADIUS", "Remote Authentication Dial-In User Service"],
    urls: ["https://freeradius.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Remote_Authentication_Dial-In_User_Service",
    type: "technology"
  },
  {
    id: "3a8d69bc-c435-40fd-8e8f-855915fca03c",
    name: "RabbitMQ",
    urls: ["https://www.rabbitmq.com/"],
    wikiUrl: "https://de.wikipedia.org/wiki/RabbitMQ",
    type: "technology"
  },
  {
    id: "424dd38a-c95e-403e-ba98-1f31651adff6",
    name: "React",
    aliases: ["reactjs"],
    urls: ["https://reactjs.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/React",
    type: "technology"
  },
  {
    id: "fbc2c357-24ed-449a-9b64-0522e7a02ff1",
    name: "Red Hat Enterprise Linux",
    aliases: ["RHEL"],
    urls: ["https://www.redhat.com/de/technologies/linux-platforms/enterprise-linux"],
    wikiUrl: "https://de.wikipedia.org/wiki/Red_Hat_Enterprise_Linux",
    type: "technology"
  },
  {
    id: "6a045b7e-bc61-4c59-8a3e-766541e6bcc4",
    name: {
      de: "Reguläre Ausdrücke",
      en: "Regular expressions"
    },
    aliases: {
      de: ["regular expression", "RegExp", "Regex"],
      en: ["RegExp", "Regex"]
    },
    type: "technology"
  },
  {
    id: "6d20e84e-5991-4639-8c7b-d9c0af2cf074",
    name: "RESTretto-Framework",
    type: "technology"
  },
  {
    id: "d4bebe3d-c3a7-4df9-9c94-dfdad5da0503",
    name: "RxJS",
    urls: ["https://rxjs.dev/"],
    type: "technology"
  },
  {
    id: "03586d9e-4ee8-467e-9a6c-7f0d510a4c49",
    name: "Samba File- & Print Server",
    aliases: ["smb", "cifs"],
    urls: ["https://www.samba.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Samba_(Software)",
    type: "technology"
  },
  {
    id: "e8b18f07-9aaa-4b73-9b93-090f7453980b",
    name: "SAP CATS",
    urls: ["https://help.sap.com/saphelp_afs64/helpdata/de/64/400b2b470211d189720000e8322d00/frameset.htm"],
    type: "technology"
  },
  {
    id: "140daedd-432c-41cd-a1cc-22f8ec5ea59c",
    name: "SAP CRM",
    aliases: {
      de: ["Customer Relationship Management", "Kundenbeziehungsmanagement"],
      en: ["Customer Relationship Management"]
    },
    urls: ["https://www.sap.com/germany/products/what-is-crm.html"],
    wikiUrl: "https://en.wikipedia.org/wiki/SAP_CRM",
    type: "technology"
  },
  {
    id: "80d8e3b2-9a5c-4b37-9be3-3a4be6807f79",
    name: "SAP ERM",
    aliases: {
      de: ["Enterprise Resource Planning", "Finanzen", "Personalwesen", "Fertigung", "Logistik", "Services", "Beschaffung"],
      en: ["Enterprise Resource Planning", "Finance", "Human Resources", "Manufacturing", "Logistics", "Services", "Procurement"],
    },
    urls: ["https://www.sap.com/germany/products/what-is-erp.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/SAP_ERP",
    type: "technology"
  },
  {
    id: "ac958541-a302-4350-95da-024caac9fe33",
    name: "SAP ERP-Anbindung via WebService und JMS",
    urls: ["https://www.sap.com/germany/products/s4hana-erp.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/SAP_ERP",
    type: "technology"
  },
  {
    id: "b0ba5260-70bd-4b9f-b04a-fb2d4bd37c34",
    name: "Sassy CSS",
    aliases: ["SCSS", "SASS"],
    urls: ["https://www.ionos.de/digitalguide/websites/web-entwicklung/sass/"],
    wikiUrl: "https://sass-lang.com/documentation",
    type: "technology"
  },
  {
    id: "1ef404db-0610-4194-a92d-5750be042af6",
    name: "Scala",
    urls: ["https://www.scala-lang.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Scala_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "641f5866-f60b-4bfa-a7f2-ff759225231d",
    name: "Scala sbt",
    aliases: ["The interactive build tool"],
    urls: ["https://www.scala-sbt.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Sbt_(software)",
    type: "technology"
  },
  {
    id: "4cd02419-db32-4978-8c39-02dd065c192d",
    name: "Scalable Vector Graphics",
    aliases: ["SVG", "skalierbare Vektorgrafik"],
    wikiUrl: "https://de.wikipedia.org/wiki/Scalable_Vector_Graphics",
    type: "technology"
  },
  {
    id: "a34141c0-de0f-4937-a777-c2367ea3b30c",
    name: "Slick",
    aliases: ["Functional Relational Mapping for Scala"],
    urls: ["http://scala-slick.org/"],
    type: "technology"
  },
  {
    id: "8e7306cf-2101-465c-ae1c-214ba7060496",
    name: "Selenium",
    urls: ["https://www.selenium.dev/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Selenium",
    type: "technology"
  },
  {
    id: "287143e2-8957-4cab-a13d-3ac68e4c57ba",
    name: "Selenium Grid",
    aliases: ["Grid"],
    urls: ["https://www.selenium.dev/documentation/en/grid/"],
    type: "technology"
  },
  {
    id: "21ba9b07-f825-44db-b9f1-107f627cb15e",
    name: "Sencha Ext JS",
    urls: ["https://www.sencha.com/products/extjs/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Ext_JS",
    type: "technology"
  },
  {
    id: "3a098462-6b89-46cc-bebe-f465bee3ad69",
    name: "SEP sesam",
    urls: ["https://www.sep.de/de/sep-sesam/"],
    type: "technology"
  },
  {
    id: "8c65c88e-ec63-4a39-ad49-89ddc69c77cd",
    name: "Simple Network Management Protocol Deamon",
    aliases: ["snmpd"],
    urls: ["http://www.net-snmp.org/tutorial/tutorial-5/demon/snmpd.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Simple_Network_Management_Protocol",
    type: "technology"
  },
  {
    id: "41382f7a-e363-416a-99d5-3781532aac56",
    name: "Single Sign-on",
    aliases: ["SSO"],
    wikiUrl: "https://de.wikipedia.org/wiki/Single_Sign-on",
    type: "technology"
  },
  {
    id: "0c2f88f1-ae4c-4662-834f-d632d4c7df9c",
    name: "Simple Logging Facade for Java",
    aliases: ["SLF4J"],
    urls: ["http://www.slf4j.org/"],
    wikiUrl: "https://en.wikipedia.org/wiki/SLF4J",
    type: "technology"
  },
  {
    id: "e9135481-228e-47ae-bdf2-9140f6b26a24",
    name: "Slack App",
    urls: ["https://api.slack.com/start"],
    type: "technology"
  },
  {
    id: "6c0bd869-9e58-40e0-b90a-5358906f91ab",
    name: "Slack Block Kit",
    urls: ["https://api.slack.com/block-kit"],
    type: "technology"
  },
  {
    id: "6090cfaa-7755-45c4-b012-fbbef0249bec",
    name: "SNORT",
    aliases: ["Network Intrusion Detection System", "Network Intrusion Prevention System", "NIDS", "NIPS"],
    wikiUrl: "https://de.wikipedia.org/wiki/Snort",
    urls: ["https://www.snort.org/"],
    type: "technology"
  },
  {
    id: "6a7e93ff-b879-487f-91f2-132d8d4a8d93",
    name: "SOAP",
    urls: ["https://www.w3.org/TR/soap/"],
    wikiUrl: "https://de.wikipedia.org/wiki/SOAP",
    type: "technology"
  },
  {
    id: "a59cd1c1-b1b4-40b3-bfa0-3aa93171c588",
    name: "SoapUI",
    urls: ["https://www.soapui.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/SoapUI",
    type: "technology"
  },
  {
    id: "9848a5e6-4024-4e40-b042-2efe83d4f81c",
    name: "SonarQube",
    urls: ["https://www.sonarsource.com/products/sonarqube/"],
    wikiUrl: "https://de.wikipedia.org/wiki/SonarQube",
    type: "technology"
  },
  {
    id: "a35458f9-60ce-49aa-90da-8b7221379c89",
    name: "SparxSystems Enterprise Architect",
    aliases: ["Enterprise Architect"],
    urls: ["https://www.sparxsystems.de/uml/enterprisearchitect-editionen/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Enterprise_Architect",
    type: "technology"
  },
  {
    id: "117bf495-07c4-4943-8168-3f55b0fd62d3",
    name: "Splunk",
    urls: ["https://www.splunk.com/de_de"],
    wikiUrl: "https://de.wikipedia.org/wiki/Splunk",
    type: "technology"
  },
  {
    id: "d628d915-6809-4fd5-9deb-c14fc4bba636",
    name: "Spring Boot",
    urls: ["https://spring.io/projects/spring-boot"],
    wikiUrl: "https://de.wikipedia.org/wiki/Spring_(Framework)",
    type: "technology"
  },
  {
    id: "d628d915-6809-4fd5-9deb-c14fc4bba636",
    name: "Spring Data",
    urls: ["https://spring.io/projects/spring-data"],
    wikiUrl: "https://de.wikipedia.org/wiki/Spring_(Framework)",
    type: "technology"
  },
  {
    id: "59966621-d585-446c-ac91-73907034ca15",
    name: "Spring Framework",
    aliases: ["Spring"],
    urls: ["https://spring.io/", "https://spring.io/projects/spring-framework"],
    wikiUrl: "https://de.wikipedia.org/wiki/Spring_(Framework)",
    type: "technology"
  },
  {
    id: "f84d387d-6151-47f6-a404-a8fd020ddce3",
    name: "Spring Remote",
    urls: ["https://docs.spring.io/spring/docs/3.2.x/spring-framework-reference/html/remoting.html"],
    wikiUrl: "https://de.wikipedia.org/wiki/Spring_(Framework)",
    type: "technology"
  },
  {
    id: "7569610f-cecb-4a2c-bd0e-19904a6cef01",
    name: "Squid Cache & Proxy Server",
    aliases: ["squid"],
    urls: ["http://www.squid-cache.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Squid",
    type: "technology"
  },
  {
    id: "07345956-4ce1-4316-95a9-5d38243441b5",
    name: "SQL",
    wikiUrl: "https://de.wikipedia.org/wiki/SQL",
    type: "technology"
  },
  {
    id: "cef0b249-7c3d-4545-a6be-7703a87ef04b",
    name: "Swift",
    urls: ["https://www.apple.com/chde/swift/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Swift_(Programmiersprache)",
    type: "technology"
  },
  {
    id: "fa5bb0c2-f73c-4656-a27b-6f903680d977",
    name: "Swing GUI-Toolkit",
    aliases: ["Swing"],
    urls: ["https://docs.oracle.com/javase/tutorial/uiswing/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Swing_(Java)",
    type: "technology"
  },
  {
    id: "2ead01b3-570d-4e31-a912-eb0fb7c05d57",
    name: "TeamCity CI/CD",
    urls: ["https://www.jetbrains.com/de-de/teamcity/"],
    wikiUrl: "https://de.wikipedia.org/wiki/TeamCity",
    type: "technology"
  },
  {
    id: "cf0ad17f-dfd3-4344-9551-991c4f8d8725",
    name: "Tempo für Jira",
    urls: ["https://www.tempo.io/de/"],
    type: "technology"
  },
  {
    id: "b47c4d26-48a8-415e-89d4-8f48592ab8b7",
    name: "TestNG",
    aliases: ["Testing Framework"],
    urls: ["https://testng.org/doc/"],
    wikiUrl: "https://de.wikipedia.org/wiki/TestNG",
    type: "technology"
  },
  {
    id: "bca9bb2f-5b5e-41bb-b4aa-a075dea29743",
    name: "Terraform",
    aliases: ["infrastructure as code software", "IAC"],
    urls: ["https://www.terraform.io/"],
    type: "technology"
  },
  {
    id: "1001e92a-fdf2-4088-9ef8-a57f86fec91e",
    name: "TeX Live",
    wikiUrl: "https://de.wikipedia.org/wiki/TeX_Live",
    urls: ["https://tug.org/texlive/"],
    type: "technology"
  },
  {
    id: "9b587627-a50d-4cae-b35c-8b687b1311e0",
    name: "TOML",
    aliases: ["Tom's Obvious, Minimal Language"],
    wikiUrl: "https://de.wikipedia.org/wiki/TOML",
    urls: ["https://toml.io/"],
    type: "technology"
  },
  {
    id: "1bc5a91c-1e19-4290-9a50-a4ce2031ee6b",
    name: "Trac Integrated SCM and Project Management",
    aliases: ["trac"],
    urls: ["https://trac.edgewall.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Trac",
    type: "technology"
  },
  {
    id: "8e060244-223b-4107-b12d-e528ca12cb2e",
    name: "Transact-SQL",
    aliases: ["T-SQL"],
    urls: ["https://docs.microsoft.com/de-de/sql/t-sql/language-reference"],
    wikiUrl: "https://de.wikipedia.org/wiki/Transact-SQL",
    type: "technology"
  },
  {
    id: "596cbc3f-c757-4323-bcec-08130f2849ae",
    name: "TSLint",
    urls: ["https://palantir.github.io/tslint/"],
    type: "technology"
  },
  {
    id: "ff25f6c6-d4e3-4cf6-b200-4194ae7830c9",
    name: "TSX",
    aliases: ["Typed JSX"],
    urls: ["https://www.typescriptlang.org/docs/handbook/jsx.html"],
    type: "technology"

  },
  {
    id: "589cbeac-a61f-48e8-a1fd-a097678447be",
    name: "TypeScript",
    urls: ["https://www.typescriptlang.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/TypeScript",
    type: "technology"
  },
  {
    id: "b9bd7699-6ebf-4290-8efe-a4b0dd447918",
    name: "Unified Modeling Language",
    aliases: ["UML"],
    urls: ["https://www.omg.org/spec/UML/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Unified_Modeling_Language",
    type: "technology"
  },
  {
    id: "5cd5bf0f-517c-44fb-91bf-c4b0f18725e6",
    name: "Visual Basic Script",
    aliases: ["VBScript", "VBS"],
    wikiUrl: "https://de.wikipedia.org/wiki/Visual_Basic_Script",
    type: "technology"
  },
  {
    id: "310d4ac3-4b55-4949-a8d2-b2586d9f0551",
    name: "Vue.js",
    urls: ["https://vuejs.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Vue.js",
    type: "technology"
  },
  {
    id: "5d8dd90c-7421-45e9-8048-375d8e952cfe",
    name: "Vuetify",
    aliases: {
      de: ["Material Design Komponenten-Framework"],
      en: ["Material Design Component Framework"],
    },
    urls: ["https://vuetifyjs.com/de-DE/"],
    type: "technology"
  },
  {
    id: "afb98792-fd0e-491d-bfbc-aeb7120e05e3",
    name: "Webalizer",
    aliases: ["Web-Analytics-Software"],
    urls: ["http://www.webalizer.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Webalizer",
    type: "technology"
  },
  {
    id: "a3fb712d-c5ed-42d4-b017-a1dce031cf09",
    name: "Web Application Description Language",
    aliases: ["WADL"],
    urls: ["https://javaee.github.io/wadl/"],
    wikiUrl: "https://de.wikipedia.org/wiki/Web_Application_Description_Language",
    type: "technology"
  },
  {
    id: "7fe3b09e-abd0-49ff-a656-db849f33808c",
    name: "Web Services Description Language",
    aliases: ["WSDL"],
    urls: ["https://www.w3.org/TR/wsdl20/"],
    wikiUrl: "https://en.wikipedia.org/wiki/Web_Services_Description_Language",
    type: "technology"
  },
  {
    id: "94576ee7-bf4e-42a8-8345-91783df3b3f8",
    name: "West Wind Framework",
    urls: ["https://west-wind.com/"],
    type: "technology"
  },
  {
    name: "PowerShell",
    id: "45382632-1bbb-4c14-b97b-a14fe4e6dabb",
    aliases: ["PowerShell Core"],
    urls: ["https://docs.microsoft.com/de-de/powershell/"],
    wikiUrl: "https://de.wikipedia.org/wiki/PowerShell",
    type: "technology"
  },
  {
    id: "965b4343-7fa6-467e-b62c-3e93d8b64028",
    name: "Xcode",
    urls: ["https://developer.apple.com/xcode"],
    wikiUrl: "https://de.wikipedia.org/wiki/Xcode",
    type: "technology"
  },
  {
    id: "eb83ca05-6080-4317-b593-88d78f65621b",
    name: "XPath",
    urls: ["https://developer.mozilla.org/en-US/docs/Web/XPath/Introduction_to_using_XPath_in_JavaScript"],
    type: "technology"
  },
  {
    id: "c7dbc9ed-15e7-4f0f-9341-e3aba760319b",
    name: "xUnit.net",
    urls: ["https://xunit.net/"],
    wikiUrl: "https://en.wikipedia.org/wiki/XUnit.net",
    type: "technology"
  },
  {
    id: "396e46cc-b0ea-400f-b4f3-beb208f2eb96",
    name: "YAML Ain’t Markup Language",
    aliases: ["YAML"],
    urls: ["https://yaml.org/"],
    wikiUrl: "https://de.wikipedia.org/wiki/YAML",
    type: "technology"
  },
  {
    id: "18c8d9d2-36a6-4a9b-834f-0fb25fec285e",
    name: "Yarn - Package Manager",
    urls: ["https://yarnpkg.com/"],
    type: "technology"
  }
]

export const technologiesAndPatterns: Array<IPattern | ITechnology> = [
  ...patterns,
  ...technologies
]
