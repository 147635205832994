import {Language} from "./model/LanguageBundle";
import {siteMetaData} from "./siteMetaData";

export interface ILanguage {
  text: string
  id: string
  shortText: string
  path: string
}

/* Language configuration objects */
export const languages: { [language in Language]: ILanguage } = {
  de: {
    text: "wechsle zu Deutsch",
    id: "de",
    shortText: "de",
    path: "/"
  },
  en: {
    text: "switch to English",
    id: "en",
    shortText: "en",
    path: "/en/"
  }
}

/* The language to fall back to if the target language page is not available. */
const defaultFallbackLanguageKey = "de"

/* The language string of the language to fall back to if the target language page is not available. */
const defaultFallbackLanguage = languages[defaultFallbackLanguageKey]

/* Returns URL and text for alternate language page or undefined it there no alternative language page exists. */
export const getSwitchLanguageUrlForCurrentLanguage = (paths: string[], href: string, language: Language): { path: string, lang: ILanguage, url: string } | undefined => {
  /* Language configuration object of the current language */
  const currentLanguage = languages[language]

  /* The destination language to switch to. */
  const languageToSwitchTo = (language === "de") ? languages.en : languages.de

  const currentLangPath = currentLanguage.path
  const destinationLangPath = languageToSwitchTo.path
  const currentPagePath = new RegExp(`${siteMetaData.siteUrl}${currentLangPath}([^#]*)(#.*)?`)
  const destinationPath = href.replace(currentPagePath, `${destinationLangPath}$1`)
  const destinationPathExists = paths.includes(destinationPath)
  if (destinationPathExists) {
    return {path: destinationPath, lang: languageToSwitchTo, url: `${siteMetaData.siteUrl}${destinationPath}`}
  } else {
    if (defaultFallbackLanguageKey !== language) {
      return {path: defaultFallbackLanguage.path, lang: defaultFallbackLanguage, url: `${siteMetaData.siteUrl}${defaultFallbackLanguage.path}`}
    }
    return undefined
  }
}
