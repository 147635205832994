import {ILanguageBundle} from "../model/LanguageBundle";

export type IStageInformation = {
  header: ILanguageBundle,
  officialVersion: ILanguageBundle
}

export const stageInformation: IStageInformation = {
  header: {
    de: "Dies ist eine Entwicklungsversion",
    en: "This is a development version"
  },
  officialVersion: {
    de: "Die offizielle Version dieses Dokuments finden Sie unter",
    en: "The official version of this document can be found at"
  }
}
