import React, {CSSProperties, FunctionComponent} from "react";
import * as styles from './InformationBox.module.scss';

interface InformationBoxProps {
  styles?: CSSProperties
}

export const InformationBox: FunctionComponent<InformationBoxProps> = (props) => {
  return <div className={styles.container} style={{ ...props.styles }}>{props.children}</div>
}
