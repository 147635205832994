import {IThings} from "../model/Things";
import {ILanguageBundle} from "../model/LanguageBundle";

export const softSkillSection: { header: ILanguageBundle, subHeader: ILanguageBundle, description: ILanguageBundle } = {
  header: {
    de: "Soft Skills",
    en: "Social skills"
  },
  subHeader: {
    de: "Soziale Kompetenzen",
    en: " "
  },
  description: {
    de: "Im folgenden Kapitel werden meine Soft Skills als Graph und Tabelle visualisiert dargestellt.",
    en: "The following chapter presents my soft skills, visualised as a graph and in table form."
  }
}

export const softSkills: IThings = {
  id: "e992bf8c-825f-420f-b71f-736d08b42910",
  name: {
    de: "Soft Skills",
    en: "Soft skills"
  },
  children: [
    {
      id: "16764703-4172-4ed6-a154-dda01a9ec17f",
      name: {
        de: "Eigenverantwortliches Handeln",
        en: "Acting on one's own responsibility"
      },
      children: [
        {
          id: "677fd0fe-a49d-4ac3-bb8e-9157a5216078",
          name: {
            de: "Analysieren",
            en: "Analysing"
          }
        },
        {
          id: "284e5e6a-c3ca-4cc0-8dd3-bf2c4348460b",
          name: {
            de: "Beraten",
            en: "Advising"
          }
        },
        {
          id: "79d1422f-1f21-41c4-a272-7ec3e8068309",
          name: {
            de: "Umsetzen",
            en: "Implementing"
          }
        },
        {
          id: "15faf66e-d1e8-4006-8d12-d231b489ca8f",
          name: {
            de: "Prüfen",
            en: "Checking / Testing"
          }
        },
        {
          id: "44ba148a-9a48-4c4b-9f8b-bcfd120b2c1e",
          name: {
            de: "Verbessern",
            en: "Improving"
          }
        }
      ]
    },
    {
      id: "8a9e8516-d7e0-490b-a4bc-070a431a0824",
      name: {
        de: "Selbst",
        en: "Myself"
      },
      children: [
        {
          id: "de55e517-3c9e-492e-bdcb-7601145a68b6",
          name: {
            de: "Introspektion",
            en: "Introspection"
          }
        },
        {
          id: "b0a159d7-f20f-4774-8e25-fe4b57d56fbe",
          name: {
            de: "Gesunde Selbstwirksamkeitserwartung",
            en: "Self-efficacy expectation"
          }
        },
        {
          id: "8e4e35ce-eb03-49e0-b03b-6a81e9608247",
          name: {
            de: "Eigenverantwortung",
            en: "Personal responsibility"
          }
        },
        {
          id: "7c5e63a9-4e8a-43b7-b4bc-335e6ec0b5e0",
          name: {
            de: "Selbstdisziplin",
            en: "Self-discipline"
          }
        },
        {
          id: "6c7b438f-cc44-455a-9e2d-195b3bee6548",
          name: {
            de: "Hohe Auffassungsgabe",
            en: "High perceptiveness"
          }
        }
      ]
    },
    {
      id: "457ff15d-7ee8-4d20-a3f7-21babe97507d",
      name: {
        de: "Andere",
        en: "Others"
      },
      children: [
        {
          id: "e9d9ce76-3e87-44f3-8a18-bd7563d4ced9",
          name: {
            de: "Hilfsbereitschaft",
            en: "Helpfulness"
          }
        },
        {
          id: "ec04c7eb-69be-42e2-9602-5cf15e383e05",
          name: {
            de: "Empathie",
            en: "Empathy"
          }
        },
        {
          id: "b5bb8934-ddca-4898-9ca4-e18346df199f",
          name: {
            de: "Kritikfähigkeit",
            en: "Critical faculties"
          }
        },
        {
          id: "01f69aac-9747-440c-824a-46735ae21f81",
          name: {
            de: "Sprachkompetenz",
            en: "Language competence"
          }
        }
      ]
    },
    {
      id: "8e77b980-8173-4580-a568-77cc1f8fe4ed",
      name: {
        de: "Zusammenarbeit",
        en: "Collaboration"
      },
      children: [
        {
          id: "17485956-91cb-4eeb-922d-962790df87b5",
          name: {
            de: "Teamfähigkeit",
            en: "Team player"
          }
        },
        {
          id: "b5885b5f-2207-4ec8-99b1-27590637ca73",
          name: {
            de: "Flexibilität",
            en: "Fexible"
          }
        },
        {
          id: "35fc8315-e376-4a03-96ec-9871ccbe5cc2",
          name: {
            de: "Engagement",
            en: "Committed"
          }
        },
        {
          id: "62e4aae9-7d0d-438c-894d-4146185c0bb4",
          name: {
            de: "Sachkompetenz",
            en: "Professional competence"
          }
        }
      ]
    }
  ]
}
