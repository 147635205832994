import React from "react";
import {TopMenu} from "./TopMenu";
import * as styles from './TopNavbar.module.scss'
import {LanguageSwitch} from "./LanguageSwitch";
import {Language} from "../model/LanguageBundle";
import {Link} from "gatsby";

// noinspection HtmlUnknownAnchorTarget
export const TopNavbar = ({language, href}: { language: Language, href: string }) => {
  return (
    // @ts-ignore
    <div className={styles.topNavbar} name="top">
      <div className={styles.logoText}><Link to="/">Bastian Nolte</Link></div>
      <LanguageSwitch language={language} href={href}/>
      <a href="#tableOfContents" className={styles.tableOfContentsButton}>&nbsp;</a>
      <TopMenu language={language} href={href}/>
    </div>
  )
}
