export type ILanguageBundle = IGenericLanguageBundle<string>
export type ILanguageBundleArray = IGenericLanguageBundle<string[]>

export interface IGenericLanguageBundle<T> {
  de: T
  en: T
}

export type Language = "de" | "en"
export type ILanguageBundleOrLocalized = ILanguageBundle | string
export type ILanguageBundleArrayOrLocalized = ILanguageBundleArray | string[]

export const isLanguageBundle = <TYPE>(value?: any): value is IGenericLanguageBundle<TYPE> => {
  return (!(
    value === undefined ||
    (value as IGenericLanguageBundle<TYPE>).de === undefined ||
    (value as IGenericLanguageBundle<TYPE>).en === undefined))
}
