import {siteMetaData} from "../../siteMetaData";
import {InformationBox} from "../InformationBox";
import React, {FunctionComponent} from "react";
import {DocType} from "../../data/DocType";
import {Language} from "../../model/LanguageBundle";
import {localizer} from "../../i18n";
import {stageInformation} from "../../data/StageInfo";

interface Props {
  path: string
  language: Language
}

export const StageInformation: FunctionComponent<Props> = ({path, language}) => {
  const t = localizer(language)

  if (siteMetaData.stage !== "production") {
    const canonicalPath = siteMetaData.getCanonicalPathOf(path)
    return (
      <InformationBox styles={{borderColor: 'orangered'}}>
        <h2>{t(stageInformation.header)}</h2>
        <ul>
          <li>Stage: {siteMetaData.stage}</li>
          <li>SiteUrl: <a href={siteMetaData.siteUrl}>{siteMetaData.siteUrl}</a></li>
          <li>DocId: {siteMetaData.getUniqueDocumentId(DocType.WEB_APP)}</li>
        </ul>
        <p>{t(stageInformation.officialVersion)} <a href={canonicalPath}>{canonicalPath}</a>.
        </p>
      </InformationBox>
    )
  }
  return <></>
}
