import {IGenericLanguageBundle, isLanguageBundle, Language} from "./model/LanguageBundle";

export const defaultLanguage = 'de'

export const t = <TYPE>(input: TYPE | IGenericLanguageBundle<TYPE>, lang: Language) => {
  if (isLanguageBundle(input)) {
    return input[lang] || input[defaultLanguage]
  }

  return input
}

/*
 * Usage example:
 * <code>
 * const t = localizer(language)
 * return (<div>{t(siteheader.title) \/* Demonstration Site *\/}</div>)
 * </code>
 */
export const localizer = (language: Language) => {
  return <TYPE>(input?: TYPE | IGenericLanguageBundle<TYPE>): TYPE => {
    return (input === undefined || Array.isArray(input))
      ? input as TYPE
      : t(input, language)
  }
}

/*
 * Returns "de-ch" if the passed language is a german one, e.g. "de" or "de-at". In all other cases the function returns "en".
 */
export const getLocale = (language: Language) => {
  switch (true) {
    case /de(-[a-z]{2})?/.test(language):
      return "de-CH"
    default:
      return "en"
  }
}

export const getLocalizedDate = (language: Language, date: string = new Date().toDateString()) => {
  return new Date(Date.parse(date)).toLocaleDateString(getLocale(language), {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const accentsMap: Record<string, string> = {
  ae: 'ä',
  ue: 'ü',
  oe: 'ö',
  a: 'á|à|ã|â',
  e: 'é|è|ê',
  i: 'í|ì|î',
  o: 'ó|ò|ô|õ',
  u: 'ú|ù|û',
  c: 'ç',
  n: 'ñ',
  '-': '( |/)+',
};

export const convertUmlauts = (text: string) => Object
  .keys(accentsMap)
  .reduce((acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur), text);

export const toId = (input: string) => {
  return convertUmlauts(input.toLowerCase())
}
