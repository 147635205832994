import * as React from 'react'
import {IProjectGroup, TProjects} from "../model/Project";
import {projects as projectsData} from "../data/Projects"
import {softSkills} from "../data/SoftSkills";
import {IThings} from "../model/Things";

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    BastianNolte: {
      projects: TProjects,
      skills: IThings
    }
  }
}

const projects = (projectsData[0] as IProjectGroup).projects

const msg = (middleBit: string = ''): string => `\n\n\n
Hi there 👋

Welcome to my resume! It looks like you know your way around the developer console. If that's more your style, here's some data about me:

${middleBit}
Feel free to explore some data about me using \`window.BastianNolte\`.
\n\n\n\n`

const consoleBit = '\tSkills: %O\n\tProjektauszüge: %O\n'

export default class WelcomeLogger extends React.Component {
  logged?: boolean

  render() {
    if (typeof window !== 'undefined') {
      if (!this.logged) {
        this.logged = true
        console.log(msg(consoleBit), softSkills, projects) // eslint-disable-line no-console
      }
      window.BastianNolte = {
        projects,
        skills: softSkills
      }
    }
    return null
  }
}
