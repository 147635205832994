import {DocType} from "./data/DocType";
import {v4 as uuid} from 'uuid';
import {languages} from "./i18nUrl";
import {Language} from "./model/LanguageBundle";
import {t} from "./i18n";

const canonicalUrl = 'https://www.bastian-nolte.name'
const stage = process.env.GATSBY_STAGE || 'development' // production, deploy-preview, branch-deploy, development
const uniqueBuildId = process.env.GATSBY_BUILD_ID || `dev-${uuid()}`
const downloadPath = '/downloads/'
const filenameResumeShort = 'bastian-nolte-cv-short'
const filenameResumeLong = 'bastian-nolte-cv'
const filenameExtensionFor3ps = '-3ps'
const experienceSinceYear = 1998

const getSiteUrl = () => {
  if (stage === 'production') return canonicalUrl
  if (process.env.GATSBY_SITE_URL) return process.env.GATSBY_SITE_URL
  if (process.env.NODE_ENV === "development") return "http://localhost:3000"
  return "http://localhost:3001"
}
const siteUrl = getSiteUrl()

const languageSpecificBaseUrl = {
  de: siteUrl,
  en: `${siteUrl}${languages.en.path}`
}

const getUniqueDocumentId = (documentType: DocType) => {
  const getIdWithPrefix = (prefix: string) => `${prefix}-${uniqueBuildId}`
  switch (documentType) {
    case DocType.WEB_APP:
      return getIdWithPrefix("web")
    case DocType.PDF_SHORT:
      return getIdWithPrefix("pdfs")
    case DocType.PDF_LONG:
      return getIdWithPrefix("pdfl")
    case DocType.PDF_SHORT_THIRD_PERSON_SINGULAR:
      return getIdWithPrefix("pdfs3")
    case DocType.PDF_LONG_THIRD_PERSON_SINGULAR:
      return getIdWithPrefix("pdfl3")
  }
}

const downloadPaths = {
  shortResume: {
    pdf: `${downloadPath}${filenameResumeShort}.pdf`,
    tex: `${downloadPath}${filenameResumeShort}.tex`,
    priority: 0.9
  },
  shortResume3ps: {
    pdf: `${downloadPath}${filenameResumeShort}${filenameExtensionFor3ps}.pdf`,
    tex: `${downloadPath}${filenameResumeShort}${filenameExtensionFor3ps}.tex`,
    priority: 0.6
  },
  resume: {
    pdf: `${downloadPath}${filenameResumeLong}.pdf`,
    tex: `${downloadPath}${filenameResumeLong}.tex`,
    priority: 0.9
  },
  resume3ps: {
    pdf: `${downloadPath}${filenameResumeLong}${filenameExtensionFor3ps}.pdf`,
    tex: `${downloadPath}${filenameResumeLong}${filenameExtensionFor3ps}.tex`,
    priority: 0.6
  },
  shortResumeEn: {
    pdf: `${downloadPath}${filenameResumeShort}.en.pdf`,
    tex: `${downloadPath}${filenameResumeShort}.en.tex`,
    priority: 0.9
  },
  resumeEn: {
    pdf: `${downloadPath}${filenameResumeLong}.en.pdf`,
    tex: `${downloadPath}${filenameResumeLong}.en.tex`,
    priority: 0.9
  },
}

const getCanonicalPathOf = (path: string) => path.replace(siteMetaData.siteUrl, siteMetaData.canonicalUrl)

const getUrlForPath = (path: string) => `${siteMetaData.siteUrl}${path}`
const getUrlForContentPath = (path: string, languaege: Language) => `${t(siteMetaData.languageSpecificBaseUrl, languaege)}${path}`.replace(/\/{2}/g, "/")

export const siteMetaData = {
  title: {
    de: 'Digitales Curriculum Vitae',
    en: 'Digital curriculum vitae'
  },
  description: {
    de: 'IT Business Consultant & Coach, Software-Architekt & Full Stack Entwickler.',
    en: 'IT Business Consultant & Coach, Software Architect & Full Stack Developer.'
  },
  overallExperienceSinceYear: experienceSinceYear,
  overallExperienceInYears: new Date().getFullYear() - experienceSinceYear,
  pdfTitle: {
    de: 'Curriculum Vitae von Bastian Nolte',
    en: 'Curriculum Vitae of Bastian Nolte'
  },
  pdfDescription: {
    de: "Der Lebenslauf von Bastian Nolte im PDF-Format.",
    en: "The curriculum vitae of Bastian Nolte in PDF format."
  },
  pdfKeywords: {
    de: "Bastian Nolte, IT Business Consultant, Software-Architekt, Full Stack Developer, Cloud, Azure, F\\#, Kotlin, Java, JavaScript, TypeScript, Curriculum Vitae, Lebenslauf",
    en: "Bastian Nolte, IT Business Consultant, Software Architect, Full Stack Developer, Cloud, Azure, F\\#, Kotlin, Java, JavaScript, TypeScript, Curriculum Vitae, Resume"
  },
  pdfFootnote: {
    de: "Curriculum Vitae von Bastian Nolte, Senior Software-Architekt, Senior IT-Consultant und Expert Full Stack Developer",
    en: "Curriculum Vitae of Bastian Nolte, Senior Software Architect, Senior IT-Consultant and Expert Full Stack Developer"
  },
  canonicalUrl: canonicalUrl,
  siteUrl: siteUrl,
  languageSpecificBaseUrl: languageSpecificBaseUrl,
  stage: stage,
  shortName: '@bastiannolte',
  author: 'Bastian Nolte',
  downloadPaths: downloadPaths,
  urls: {
    de: {
      firstPerson: {
        web: siteUrl,
        pdfLong: `${siteUrl}${downloadPaths.resume.pdf}`,
        pdfShort: `${siteUrl}${downloadPaths.shortResume.pdf}`
      },
      thirdPerson: {
        web: `${siteUrl}/bastian-nolte-3ps`,
        pdfLong: `${siteUrl}${downloadPaths.resume3ps.pdf}`,
        pdfShort: `${siteUrl}${downloadPaths.shortResume3ps.pdf}`
      }
    },
    en: {
      firstPerson: {
        web: `${languageSpecificBaseUrl.en}`,
        pdfLong: `${siteUrl}${downloadPaths.resumeEn.pdf}`,
        pdfShort: `${siteUrl}${downloadPaths.shortResumeEn.pdf}`
      },
    }
  },
  getUniqueDocumentId,
  getCanonicalPathOf,
  getUrlForPath,
  getUrlForContentPath
}
