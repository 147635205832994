import React, {FunctionComponent, useEffect} from 'react'
import {Helmet} from 'react-helmet'
import WelcomeLogger from "./WelcomeLogger";
import {TopNavbar} from "./TopNavbar";
import * as styles from "./Layout.module.scss"
import {siteMetaData} from "../siteMetaData";
import {StageInformation} from "./resume/StageInformation";
import {Language} from "../model/LanguageBundle";
import {graphql, useStaticQuery} from "gatsby";
import {getSwitchLanguageUrlForCurrentLanguage} from "../i18nUrl";
import {localizer} from "../i18n";

type Props = {
  title?: string
  description?: string
  helmetKids?: React.ReactNode
  href: string
  imageUrl?: string
  imageUrl400x400?: string
  language?: Language
}

export const Layout: FunctionComponent<Props> = (
  {
    title = siteMetaData.title.de,
    description = siteMetaData.description.de,
    helmetKids,
    href,
    imageUrl = `${siteMetaData.siteUrl}/open-graph.jpg`,
    imageUrl400x400 = `${siteMetaData.siteUrl}/open-graph-400x400.jpg`,
    language = "de",
    children
  }) => {
  useEffect(() => {
    // Scroll to Anchor (Hash)
    const menuHeight = 51
    const {hash} = location
    if (hash) {
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(hash)
        if (anchor) {
          const offset = anchor.getBoundingClientRect().top + window.scrollY - menuHeight
          window.scroll({top: offset, left: 0})
        }
      })
    }
  }, []); // The [] indicates that we will run this code block only once.

  const alternateLanguageLink = (() => {
    /* Load paths of all pages */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data: { allSitePage: { nodes: { path: string }[] } } = useStaticQuery(graphql`
      query {
        allSitePage {
          nodes {
            path
          }
        }
      }
    `)

    /* Paths of all pages as an array of strings. */
    const allPagesPaths = data.allSitePage.nodes.map(n => n.path)
    return getSwitchLanguageUrlForCurrentLanguage(allPagesPaths, href, language)
  })()

  const t = localizer(language)

  return (
    <>
      <WelcomeLogger/>
      <Helmet
        titleTemplate="Bastian Nolte | %s"
        defaultTitle={title}
      >
        <html lang={language} itemScope itemType="http://schema.org/WebPage"/>
        <meta charSet="utf-8"/>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="canonical" href={siteMetaData.getCanonicalPathOf(href)}/>
        {alternateLanguageLink && <link rel="alternate" hrefLang={language} href={href}/>}
        {alternateLanguageLink && <link rel="alternate" hrefLang={alternateLanguageLink.lang.id} href={alternateLanguageLink.url}/>}
        <meta name="description" content={description}/>
        {/* Google Search Console */}
        <meta name="google-site-verification" content="tBWNWsmyY2s43uAqhWXjb2Svm5nF6pmalvhzsRGpYCk"/>

        {/* OpenGraph stuff */}
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:type" content="website"/>
        <meta property="og:locale" content={language}/>

        {/* Images for Facebook (uses first image-tag) - https://developers.facebook.com/tools/debug/ */}
        {/* https://yoast.com/advanced-technical-seo-social-image-ogimage-tags/ */}
        <meta property="og:image" content={imageUrl}/>
        <meta property="og:image:secure_url" content={imageUrl}/>
        <meta property="og:image:type" content="image/jpeg"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:image:alt" content={t({de: "Foto von Bastian Nolte", en: "Photo of Bastian Nolte"})}/>

        {/* Images for most all Others?, Twitter, WhatsApp uses last image-tag */}
        <meta property="og:image" content={imageUrl400x400}/>
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="400"/>
        <meta property="og:image:height" content="400"/>

        {/* Schema.org for google stuff */}
        <meta itemProp="name" content={title}/>
        <meta itemProp="description" content={description}/>
        <meta itemProp="image" content={imageUrl}/>
        {/* Twitter */}
        <meta property="twitter:card" content="summary"/>
        <meta property="twitter:creator" content={siteMetaData.author}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={description}/>

        {helmetKids}
      </Helmet>
      <TopNavbar language={language} href={href}/>
      <div className={styles.topMenuDistancingContainer}>
        <StageInformation path={href} language={language}/>
        {children}
      </div>
    </>
  )
}
