import React from "react"
import * as styles from "./LanguageSwitch.module.scss"
import {Language} from "../model/LanguageBundle";
import {graphql, Link, useStaticQuery} from "gatsby";
import {getSwitchLanguageUrlForCurrentLanguage} from "../i18nUrl";

export const LanguageSwitch = ({href, language}: { href: string, language: Language }) => {
  /* Load paths of all pages */
  const data: { allSitePage: { nodes: { path: string }[] } } = useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  /* Paths of all pages as an array of strings. */
  const allPagesPaths = data.allSitePage.nodes.map(n => n.path)
  const link = getSwitchLanguageUrlForCurrentLanguage(allPagesPaths, href, language)

  return (
    <>{link && /* Show link only for pages with equivalent in other language. */
    <div className={styles.languageSwitch}>
      <Link className={styles.short} to={link.path}>{link.lang.shortText}</Link>
      <Link className={styles.long} to={link.path}>{link.lang.text}</Link>
    </div>}
    </>
  )
}
